import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton, Typography, Paper, useTheme, InputBase, Divider } from "@mui/material";
import ReactTextFormat from 'react-text-format';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function LoginPopout({data}) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));


  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {


   };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
   <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Company Info</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <form>


            <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "60vh" }}
      >
        <Grid item xs={3}>
        <Paper
        elevation={5}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,  }}
    //   onSubmit={preventDefault}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Create or enter your secret..."
        inputProps={{ 'aria-label': 'Secret' }}
        type="password"
        onKeyPress={e => e.key === 'Enter' && handleSubmit()}
        
              />
      
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
      onClick={() => handleSubmit()}
     >
        <ArrowForwardIcon />
      </IconButton>
    </Paper>
   

        </Grid>
  </Grid>




            </form>      
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}