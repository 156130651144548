import React, {useState} from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

    const CyberSecurityScore = ({ jsonData, border, data, godLoading, forReport }) => {
        const [PaperElevation, setPaperElevation] = useState(0);
    
     
    if(!forReport){
        return (
        <Box sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto' }} >
            <Card elevation={!border ? -1 : PaperElevation} sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto', backgroundColor: "#fcfcfc"  }}>
                <CardContent>
                {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> : <Typography variant="h5" color="textSecondary" sx={{ textAlign: 'center' }} gutterBottom>
                    Cyber Security Score
                </Typography>}
                    {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 115, textAlign: "center", marginLeft: 0 }}/>  : <Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                        {data} / 100
                    </Typography>}
                </CardContent>
            </Card>
        </Box>
    );}
    else return (
        <Box sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto' }} >
        <Card elevation={!border ? -1 : PaperElevation} sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto', backgroundColor: "#ffffff"  }}>
            <CardContent>
            {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> : <Typography variant="h5" color="textSecondary" sx={{ textAlign: 'center' }} gutterBottom>
                Cyber Security Score
            </Typography>}
                {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 115, textAlign: "center", marginLeft: 0 }}/>  : <Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                    {data} / 100
                </Typography>}
            </CardContent>
        </Card>
    </Box>
    )
};

export default CyberSecurityScore;