import { Box, Button, useTheme, TextField } from "@mui/material";
import {React, useState} from "react";
import { tokens } from "../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { preventDefault } from "@fullcalendar/core";
import axios from 'axios'
import { useAuth0 } from "@auth0/auth0-react";


const DomainEntry = ({data, close}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [domainName, setDomain] = useState('');
 


  const handleSubmit = (event) => {
    event.preventDefault()
    const domain = { domain: domainName};
    axios.post(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/domain/create', domain)
        .then(response => data(response.data))
        .then(close(null))
      
    fetch(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/whois');
    // fetch(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/blacklists');

  };

  return (
    <Box width="100%" p="10px 10px 10px 10px">
     
          <form onSubmit={handleSubmit}>
            <Box
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Domain to Track"
                name="domain"
                onChange={(event) => setDomain(event.target.value)}
              />
             
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Entry
              </Button>
            </Box>
          </form>
     
    </Box>
  );
};

export default DomainEntry;