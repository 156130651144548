// export default ReportBuilder;
// ReportBuilder.jsx

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  RadioGroup,
  Radio,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth0 } from "@auth0/auth0-react";
import TopHostsBarChart from "../../components/TopHostsBarChart";
import html2canvas from "html2canvas";
import CVSSDistributionChart from "../../components/CVSSDistributionChart";
import UniqueHostCount from "../../components/UniqueHostCount";
import VulnerabilityCount from "../../components/VulnerabilityCount";
import CyberSecurityScore from "../../components/CyberSecurityScore";
import VulnerabilitiesOverTime from "../../components/VulnerabilitiesOverTime";
import OperatingSystemsBarChart from "../../components/OperatingSystemsBarChart";
import BarChart from "../../components/BarChart";
import PairO365Button from "../../components/O365PairButton";
import InfoIcon from "@mui/icons-material/Info";

const ReportBuilder = () => {
  const [reportType, setReportType] = useState("currentClient"); // options: 'currentClient', 'domain', 'executiveReport'
  const [domainName, setDomainName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [dates, setDates] = useState([]);
  const [notes, setNotes] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [executiveName, setExecutiveName] = useState("");
  const [godLoading, setGodLoading] = useState(false);
  const [correctedCompanyName, setCorrectedCompanyName] = useState("");
  const [cloudNotes, setCloudNotes] = useState("");
  const [storeO365Data, setStoreO365Data] = useState(true);
  const [o365AuthenicationFailed, setO365AuthenicationFailed] = useState(false);
  const [missingData, setMissingData] = useState(false);

  const chartRefs = useRef([]);

  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/api/all/companies`)
      .then((response) => {
        setCompanies(response.data);
      });
  }, []);

  useEffect(() => {
    // Initialize refs only once
    if (chartRefs.current.length === 0) {
      chartRefs.current = Array.from({ length: 8 }, () => React.createRef());
    }
  }, []);

  useEffect(() => {
    if (reportType === "currentClient" || "executiveReport" && selectedCompanyName) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}/api/available-dates/${selectedCompanyName}`
        )
        .then((response) => {
          setDates(response.data);
          if (response.data.length > 0) {
            setSelectedDate();
            setShowNotes(false);
            setNotes("");
            setCloudNotes("");
          }
        });
    }
  }, [reportType, selectedCompanyName]);

  const captureChart = async (chartRef) => {
    const chartElement = chartRef.current;
    if (!chartElement) {
      console.error("Chart element not found for ref:", chartRef);
      return null;
    }
    const canvas = await html2canvas(chartElement, {
      width: chartElement.scrollWidth,
      height: chartElement.scrollHeight,
      windowWidth: chartElement.scrollWidth,
      windowHeight: chartElement.scrollHeight,
      scale: 2, // Increase scale for better quality
    });
    return canvas.toDataURL("image/png");
  };

  const handleDownload = async () => {
    setLoading(true); // Start loading
    if (!showNotes) {
      setNotes("");
    }
    try {
      if (reportType === "currentClient") {
        if (selectedDate && selectedCompanyName) {
          // Existing logic for current client report
          const response = await axios.get(
            `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}`
          );
          setJsonData(response.data);

          // Wait for the charts to render
          setTimeout(async () => {
            const images = {};
            for (let i = 0; i < chartRefs.current.length; i++) {
              const image = await captureChart(chartRefs.current[i]);
              if (image) {
                images[`chart${i + 1}`] = image;
              }
            }

            // Send data and images to backend
            const pdfResponse = await axios.post(
              `${process.env.REACT_APP_APIURL}/api/pdf/${selectedCompanyName}/${selectedDate}`,
              { images, data: response.data, notes, reportType },
              { responseType: "blob" }
            );

            // Download the generated PDF
            const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `argus100_${selectedCompanyName}_${selectedDate}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setUploadSuccess(true);
            setLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          return;
        }
      } else if (reportType === "domain") {
        if (domainName) {
          // Logic to generate domain report
          const response = await axios.post(
            `${process.env.REACT_APP_APIURL}/api/pdf/domain-report`,
            { domain: domainName, notes },
            { responseType: "blob" }
          );

          // Download the generated PDF
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `domain_report.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setUploadSuccess(true);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      } else if (reportType === "executiveReport") {
        if (selectedCompanyName) {
          if(!selectedDate){
            setLoading(false);
            setMissingData(true);
            return;
          }
            else {

          const response = await axios.get(
            `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}`

          );
          
         
          setJsonData(response.data);

          setTimeout(async () => {
            const images = {};
            for (let i = 0; i < chartRefs.current.length; i++) {
              const image = await captureChart(chartRefs.current[i]);
              if (image) {
                images[`chart${i + 1}`] = image;
              }
            }
            // Send data and images to backend for executive report
            let pdfResponse;
            try {
              pdfResponse = await axios.post(
              `${process.env.REACT_APP_APIURL}/api/pdf/${selectedCompanyName}/${selectedDate}`,
              {
                images,
                data: response.data,
                notes,
                reportType,
                storeO365Data,
                executiveName,
                correctedCompanyName,
                cloudNotes,
              },
              { responseType: "blob" }
              );
            } catch (error) {
              if (error.response && error.response.status === 401 ) {
              setLoading(false);
              setO365AuthenicationFailed(true);
              setSelectedDate(selectedDate);
              return;
              } else {
              throw error;
              }
            }
            
              console.log("pdfResponse", pdfResponse);


              const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `argus100_executive_${selectedCompanyName}.pdf`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUploadSuccess(true);
              setLoading(false);
            
          }, 2000); 
        } 

        } else {
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error("Error generating report:", error);
      setLoading(false);
    }
  };

  const handleGenerateNotes = async () => {
    setNotes("");
    setCloudNotes("");
    setAiLoading(true);
    try {
      let response;
      if (reportType === "currentClient") {
        response = await axios.post(
          `${process.env.REACT_APP_APIURL}/api/ai/completions/argus100`,
          { company: selectedCompanyName, csv: selectedDate }
        );
      } else if (reportType === "executiveReport") {
        response = await axios.post(
          `${process.env.REACT_APP_APIURL}/api/ai/completions/executive`,
          { company: selectedCompanyName, csv: selectedDate }
        );
      } else if (reportType === "domain") {
        response = await axios.post(
          `${process.env.REACT_APP_APIURL}/api/ai/completions/domain`,
          { domain: domainName }
        );
      }
      setNotes(response.data.notes);
      if (reportType === "executiveReport"){setCloudNotes(response.data.o365Notes); }
      
    } catch (error) {
      console.error("Error generating notes:", error);
      if (error.response && error.response.status === 401 || error.response.status === 404) {
        setSelectedDate();
        setO365AuthenicationFailed(true);
      }
      if (error.respnse && error.response.status === 500) {
        setMissingData(true);
      }
    } finally {
      setAiLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (!isAuthenticated) {
    return <div>Not Authenticated</div>;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ ml: 10, mt: 2, width: "500px" }}>
        <h2>Download Report</h2>

        {/* Report Type Selection */}
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <RadioGroup
            row
            aria-label="report-type"
            name="reportType"
            value={reportType}
            onChange={(e) => {
              setReportType(e.target.value);
              // Clear fields when switching types if necessary
              setSelectedDate();
              setDomainName("");
              setNotes("");
            }}
          >
            <FormControlLabel
              value="currentClient"
              control={<Radio />}
              label="Current Client"
            />
            <FormControlLabel
              value="domain"
              control={<Radio />}
              label="Domain"
            />
            <FormControlLabel
              value="executiveReport"
              control={<Radio />}
              label="Executive Report"
            />
          </RadioGroup>
        </FormControl>

        {/* Executive Report Section */}
        {reportType === "executiveReport" && (
          <>
            {/* Company Selection for Executive Report */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="executive-company-select-label">DB Company</InputLabel>
              <Select
                labelId="executive-company-select-label"
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedCompany(e.target.value);
                  const company = companies.find(
                    (company) => company._id === e.target.value
                  );
                  setSelectedCompanyName(company ? company.company : "");
                }}
                label="Company"
                sx={{ mb: 2 }}
              >
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.company}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Executive Name"
                variant="outlined"
                fullWidth
                onChange={(e) => setExecutiveName(e.target.value)}
                sx={{ mb: 2 }}
              />
               <TextField
                label="Corrected Company Name"
                variant="outlined"
                fullWidth
                onChange={(e) => setCorrectedCompanyName(e.target.value)}
              />
            </FormControl>
            {dates.length > 0 && (
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="date-select-label">Report Date</InputLabel>
                <Select
                  labelId="date-select-label"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  label="Report Date"
                >
                  {dates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {selectedCompany ? (
              <PairO365Button companyId={selectedCompanyName} />
            ) : null}

          </>
        )}

        {/* Current Client Section */}
        {reportType === "currentClient" && (
          <>
            {/* Company Selection */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="company-select-label">Company</InputLabel>
              <Select
                labelId="company-select-label"
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedCompany(e.target.value);
                  const company = companies.find(
                    (company) => company._id === e.target.value
                  );
                  setSelectedCompanyName(company ? company.company : "");
                }}
                label="Company"
              >
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Date Selection */}
            {dates.length > 0 && (
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="date-select-label">Report Date</InputLabel>
                <Select
                  labelId="date-select-label"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  label="Report Date"
                >
                  {dates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}

        {/* Domain Report Section */}
        {reportType === "domain" && (
          <>
            {/* Domain Name Input */}
            <TextField
              label="Domain Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
            />
          </>
        )}

        {/* Add Notes Option */}
        {(selectedDate || domainName || selectedCompanyName) && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNotes}
                  onChange={() => setShowNotes(!showNotes)}
                  name="showNotes"
                />
              }
              label="Add Notes"
            />
          </Box>
        )}

        {/* Notes TextField */}
        {showNotes && (
          reportType === "executiveReport" ? (
            <Box sx={{ mb: 2 }}>
          
            <TextField
              label="On Prem Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Cloud Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={cloudNotes}
              onChange={(e) => setCloudNotes(e.target.value)}
            />

         
              <Button
                variant="contained"
                color="secondary"
                onClick={handleGenerateNotes}
                disabled={aiLoading}
                sx={{ mt: 2 }}
              >
                {aiLoading ? <CircularProgress size={24} /> : "Generate AI Notes"}
              </Button>
        
          </Box>) : (

          <Box sx={{ mb: 2 }}>
          
            <TextField
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            {reportType !== "domain" && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleGenerateNotes}
                disabled={aiLoading}
                sx={{ mt: 2 }}
              >
                {aiLoading ? <CircularProgress size={24} /> : "Generate AI Notes"}
              </Button>
            )}
          </Box>)
        )}
{/* additional questions for executive report */}
        {reportType === "executiveReport" && (
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={storeO365Data}
                  onChange={(e) => setStoreO365Data(e.target.checked)}
                />
              }
              label="Store this months O365 Data?"
            />
             <Tooltip title="When enabled, this option will store this month's O365 data for future reference in the executive report. If this is box is checked and there's already data set for this month, it will overwrite this months O365 Data score with the current data retrieved from O365. ">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          </Box>
         
      
        )}



        {/* Download Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          disabled={
            loading ||
            (reportType === "currentClient" && !selectedDate) ||
            (reportType === "domain" && !domainName) ||
            (reportType === "executiveReport" && !selectedCompanyName)
          }
          sx={{ mt: 1, mb: 10 }} 
        >
          {loading ? <CircularProgress size={24} /> : "Download Report"}
        </Button>

        {/* Hidden Charts for PDF Generation */}
        {(reportType === "currentClient" || reportType === "executiveReport") && (
          <React.Fragment>
            {/* Chart 1 */}
            <div
              ref={chartRefs.current[0]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "950px",
                height: "325px",
              }}
            >
              <TopHostsBarChart
                forReport={true}
                selectedDate={selectedDate}
                border={false}
                data={jsonData.topHostBarChart}
                company={selectedCompanyName}
                godLoading={godLoading}
              />
            </div>

            {/* Chart 2 */}
            <div
              ref={chartRefs.current[1]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "950px",
                height: "325px",
              }}
            >
              <CVSSDistributionChart
                selectedDate={selectedDate}
                forReport={true}
                border={false}
                data={jsonData.cvssDistribution}
                company={selectedCompanyName}
                godLoading={godLoading}
              />
            </div>

            {/* Chart 3 - Fixed Size */}
            <div
              ref={chartRefs.current[2]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "150px",
                height: "150px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UniqueHostCount
                data={jsonData.uniqueHostCount}
                forReport={true}
                border={false}
                date={selectedDate}
                company={selectedCompanyName}
                godLoading={godLoading}
                sx={{ width: "100%", height: "100%" }}
              />
            </div>

            {/* Chart 4 - Fixed Size */}
            <div
              ref={chartRefs.current[3]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "150px",
                height: "150px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <VulnerabilityCount
                border={false}
                forReport={true}
                data={jsonData.vulnerabilityCount}
                godLoading={godLoading}
                sx={{ width: "100%", height: "100%" }}
              />
            </div>

            {/* Chart 5 - Fixed Size */}
            <div
              ref={chartRefs.current[4]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "150px",
                height: "150px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CyberSecurityScore
                forReport={true}
                jsonData={jsonData}
                border={false}
                data={jsonData.cybersecurityScore}
                godLoading={godLoading}
                sx={{ width: "100%", height: "100%" }}
              />
            </div>

            {/* Chart 6 */}
            {dates.length > 0 && (
              <div
                ref={chartRefs.current[5]}
                style={{ 
                  position: "absolute", 
                  top: "-9999px", 
                  left: "-9999px",
                  width: "950px",
                  height: "325px",
                }}
              >
                <VulnerabilitiesOverTime
                  selectedCompanyName={selectedCompanyName}
                  border={false}
                  forReport={true}
                  availableDates={dates}
                  godLoading={godLoading}
                />
              </div>
            )}

            {/* Chart 7 */}
            <div
              ref={chartRefs.current[6]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "950px",
                height: "325px",
              }}
            >
              <OperatingSystemsBarChart
                border={false}
                selectedDate={selectedDate}
                company={selectedCompanyName}
                data={jsonData.operatingSystems}
                forReport={true}
                godLoading={godLoading}
              />
            </div>

            {/* Chart 8 */}
            <div
              ref={chartRefs.current[7]}
              style={{ 
                position: "absolute", 
                top: "-9999px", 
                left: "-9999px",
                width: "950px",
                height: "325px",
              }}
            >
              <BarChart
                selectedDate={selectedDate}
                jsonData={jsonData}
                border={false}
                data={jsonData.barChart}
                company={selectedCompanyName}
                godLoading={godLoading}
                forReport={true}
              />
            </div>
          </React.Fragment>
        )}

        {/* Snackbar Notification */}
        <Snackbar
          open={missingData}
          autoHideDuration={4000}
          onClose={() => setMissingData(false)}
          message="Please select a date for the report CSV."
          backgroundColor="red"
        />
        <Snackbar
          open={o365AuthenicationFailed}
          autoHideDuration={4000}
          onClose={() => setO365AuthenicationFailed(false)}
          message="Report Generating Failed! Please authenticate O365 account."
          backgroundColor="red"
        />
        <Snackbar
          open={uploadSuccess}
          autoHideDuration={4000}
          onClose={() => setUploadSuccess(false)}
          message="Report generated successfully!"
        />
      </Box>
    </LocalizationProvider>
  );
};

export default ReportBuilder;
