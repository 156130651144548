import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from 'react-responsive';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';


const RiskBarChart = (props) => {
    const { user } = useAuth0();
    const [chartData, setChartData] = useState([]);
    const [paperElevation, setPaperElevation] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedBar, setSelectedBar] = useState(null);
    const [gridData, setGridData] = useState([]);
    const { border, data, selectedDate, company, godLoading, forReport } = props;
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

    const handleBarClick = (data, index) => {
        console.log(data)
        setSelectedBar(data.risk);
        setDialogOpen(true);
        fetchGridData(data.risk); // Fetch data for the grid when a bar is clicked
    };

    const fetchGridData = async (risk) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${selectedDate}/risk/${risk}`);
            const modifiedData = response.data.map((item, index) => ({ ...item, id: index + 1 }));
            setGridData(modifiedData);
        } catch (error) {
            console.error('Error fetching grid data:', error);
        }
    };

    useEffect(() => {
        setChartData(data);
    }, [user, data]);

    const columns = [
        { field: 'Host', headerName: 'Host', width: 150, editable: true },
        { field: 'Name', headerName: 'Name', width: 150, editable: true },
        { field: 'CVE', headerName: 'CVE', width: 150, editable: true },
        { field: 'Synopsis', headerName: 'Synopsis', width: 150, editable: true },
        { field: 'Description', headerName: 'Description', width: 150, editable: true },
        { field: 'Solution', headerName: 'Solution', width: 150, editable: true },
        { field: 'See Also', headerName: 'See Also', width: 150, editable: true },
    ];


    if(!forReport){
    return (
        <div>
            {isMobile ? (
                <Box sx={border ? { width: 350, height: 150 } : { width: 880, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                    <Paper elevation={paperElevation}>
                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: "#fcfcfc" }}>
                        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: 250, marginLeft:15 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities by Risk Level </Typography>}
                            <ResponsiveContainer width={350} height={150}>
                               { godLoading ?       <Skeleton variant="rounded" height={150} width={ 490} /> : <BarChart
                                    data={chartData}
                                    margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="risk" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                                </BarChart>}
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </Box>
            ) : (
                <Box sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}  >
                    <Paper elevation={!border ? -1 : paperElevation} backgroundColor="#ffffff">
                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: "#fcfcfc" }}>
                        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: 250, marginLeft:15 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities by Risk Level </Typography>}
                            <ResponsiveContainer width={border ? 490 : 880} height={270}>
                               { godLoading ?       <Skeleton variant="rounded" height={150} width={ 490} /> : <BarChart
                                    data={chartData}
                                    margin={{ top: 10, right: 5, left: 5, bottom: 10 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="risk" />
                                    <YAxis />
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                                </BarChart>}
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </Box>
            )}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth PaperProps={{ style: { width: '75%'} } }>
                <DialogTitle>Risk Level: {selectedBar}</DialogTitle>
                <DialogContent>
                    {selectedBar && (
                        <div>
                            <div style={{ height: 550, width: '100%' }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        rows={gridData}
                                        columns={columns}
                                        components={{
                                        Toolbar: GridToolbarContainer,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            children: [
                                                <GridToolbarQuickFilter />,
                                                <GridToolbarExport />
                                            ],
                                        },
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );}



    else return (

        <div>
            {isMobile ? (
                <Box sx={border ? { width: 350, height: 150 } : { width: 880, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                    <Paper elevation={paperElevation}>
                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: "#ffffff" }}>
                        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '1rem', width: 250, marginLeft:15 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities by Risk Level </Typography>}
                            <ResponsiveContainer width={350} height={150}>
                               { godLoading ?       <Skeleton variant="rounded" height={150} width={ 490} /> : <BarChart
                                    data={chartData}
                                    margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="risk" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                                </BarChart>}
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </Box>
            ) : (
                <Box sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}  >
                    <Paper elevation={!border ? -1 : paperElevation} backgroundColor="#ffffff">
                        <div style={{ textAlign: 'center', padding: '20px', backgroundColor: "#ffffff" }}>
                            <ResponsiveContainer width={border ? 490 : 880} height={270}>
                               { godLoading ?       <Skeleton variant="rounded" height={150} width={ 490} /> : <BarChart
                                    data={chartData}
                                    margin={{ top: 10, right: 5, left: 5, bottom: 10 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="risk" style={{ fontWeight: 'bold' }} />
                                    <YAxis style={{ fontWeight: 'bold' }}/>
                                    <Tooltip />
                                    {/* <Legend /> */}
                                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                                </BarChart>}
                            </ResponsiveContainer>
                        </div>
                    </Paper>
                </Box>
            )}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth PaperProps={{ style: { width: '75%'} } }>
                <DialogTitle>Risk Level: {selectedBar}</DialogTitle>
                <DialogContent>
                    {selectedBar && (
                        <div>
                            <div style={{ height: 550, width: '100%' }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        rows={gridData}
                                        columns={columns}
                                        components={{
                                        Toolbar: GridToolbarContainer,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            children: [
                                                <GridToolbarQuickFilter />,
                                                <GridToolbarExport />
                                            ],
                                        },
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
};

export default RiskBarChart;