import React, {useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Container, Typography, Paper, Box } from '@mui/material';
import { useMediaQuery } from 'react-responsive'
import Skeleton from '@mui/material/Skeleton';


// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         padding: theme.spacing(2),
//         textAlign: 'center',
//         color: theme.palette.text.secondary,
//     },
// }));

const CVSSDistributionChart = ({ border, data, godLoading, forReport }) => {
    const [paperElevation, setPaperElevation] = useState(0);
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });


    // const classes = useStyles();
   
    const chartData = data;
   if(!forReport){ 
    return (
        <div>
        {isMobile ? (<Box  sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >CVSS Score Distribution</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
         
                <BarChart
                 width={350}
                        height={150}
                        data={chartData}
                        margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="CVSS Range" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#1491CB" />
                </BarChart>}
                </div>
                </Box>
            ) : (
                <Box sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>

                {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >CVSS Score Distribution</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
         
                <BarChart
                width={border ? 1190 : 1760} height={300} data={chartData}
                        margin={{ top: 10, right: 5, left: 5, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="CVSS Range" />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="Count" fill="#1491CB" />
                </BarChart>}
                </div>
                </Box>


            )
            }


        </div>

        

    )}else { return(
        <div>
        {isMobile ? (<Box  sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >CVSS Score Distribution</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
         
                <BarChart
                 width={350}
                        height={150}
                        data={chartData}
                        margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="CVSS Range" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Count" fill="#1491CB" />
                </BarChart>}
                </div>
                </Box>
            ) : (
                <Box sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>

              
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
         
                <BarChart
                width={border ? 880 : 880} height={300} data={chartData}
                        margin={{ top: 10, right: 5, left: 5, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="CVSS Range" style={{fontWeight: 'bold'}}/>
                    <YAxis style={{fontWeight: 'bold'}} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="Count" fill="#1491CB" />
                </BarChart>}
                </div>
                </Box>


            )
            }


        </div>

        )


        }

}

export default CVSSDistributionChart;
