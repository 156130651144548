import { Box, useTheme, Button, TextField } from "@mui/material";
import {React, useState, useEffect} from "react";
import { Formik } from "formik";
import { tokens } from "../../theme";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import axios from "axios"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Routes, Route, Navigate } from "react-router-dom";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [allCompanies, setAllCompanies] = useState([])
  const [companyName, setCompanyName] = useState("")
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);




  useEffect(()=>{
    fetch(process.env.REACT_APP_APIURL+'/api/all/companies')
    .then((response)=> response.json())
    .then((json) => {setAllCompanies(json)})

}, []);

  const handleFormSubmit = (event) => {
    event.preventDefault()
    const newCompany = { company: companyName };
    axios.post(process.env.REACT_APP_APIURL+'/api/create/company', newCompany)
  };
  const handleReportSubmit = async () => {
    const response = await fetch(process.env.REACT_APP_APIURL+'/generate-pdf-test-report', {
      method: 'POST',
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Nessus_Scan_Report.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }}



  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "company",
      headerName: "Company Name",
      flex: 1,
      cellClassName: "name-column--cell",
    }
  ];

  return (
    <Box m="20px">
      <Header title="Create Company" subtitle="Create a New Company Profile" />

  
          <form onSubmit={handleFormSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Company Name"
                onChange={(event) => setCompanyName(event.target.value)}
                name="companyName"
                sx={{ gridColumn: "span 4" }}
              />
             
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create New Company
              </Button>
            </Box>
          </form>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button onClick={handleReportSubmit} color="secondary" variant="contained">
                Create Report Test
              </Button>
            </Box>
     <Box
     m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}>
     <DataGrid
          rows={allCompanies}
          columns={columns}
          loading={!allCompanies.length}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
          getRowId={(row) => (row._id)}
        />      
     </Box>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
};

export default Form;
