import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { Box, Paper, Dialog, DialogTitle, DialogContent, List, ListItem, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useMediaQuery } from 'react-responsive'
import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';


const TopHostsBarChart = (props) => {
    const { user } = useAuth0();
    const [chartData, setChartData] = useState([]);
    const [selectedHost, setSelectedHost] = useState(null);
    const [hostVulnerabilities, setHostVulnerabilities] = useState([]);
    const [open, setOpen] = useState(false);
    const [paperElevation, setPaperElevation] = useState(3);
    const { border, data, date, company, godLoading, forReport } = props;
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
    const [loading, setLoading] = useState(false);

  


    const handleBarClick = async (data) => {
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`);
        console.log(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`)
        setSelectedHost(data.host);
        setHostVulnerabilities(response.data);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: '100' },
        { field: 'Protocol', headerName: 'Protocol', width: '100' },
        { field: 'Plugin ID', headerName: 'Plugin ID', width: '100'},
        { field: 'Risk', headerName: 'Risk', width: '100'},
        { field: 'Name', headerName: 'Name', width: '300',  editable: true},
        // ... add more columns as per your data structure
    ];


if (!forReport){
    return (
        <div>
        {isMobile ? (<Box  sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
        {/* <Paper elevation={paperElevation}> */}
            <div style={{ textAlign: 'center', padding: '20px' }}>
                {godLoading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :  <Typography variant='h3' fontWeight={"bold"} >Top 5 Most Vulnerable Hosts </Typography>}
               { godLoading ?       <Skeleton variant="rounded" height={150} />
:  <BarChart
                
                  width={350}
                     height={150}
                     data={data}
                    margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                 >
                     <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="host"  />
                    <YAxis />
                    <Tooltip />
                     <Legend />
                    <Bar dataKey="count" fill="#8884d8" onClick={handleBarClick} cursor="pointer" />
                </BarChart>}
            </div>
        {/* </Paper> */}
    
        <Dialog 
onClose={handleClose} 
open={open} 
maxWidth="md" 
fullWidth 
PaperProps={{ style: { width: '95%' } }}
>
<DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
<DialogContent>
    <DataGrid 
          components={{
        Toolbar: GridToolbarContainer,
    }}
    componentsProps={{
        toolbar: {
            children: [
                <GridToolbarQuickFilter />,
                <GridToolbarExport />
            ],
        },
    }}
        rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
        columns={columns} 
        pageSize={50} 
        autoHeight
        
    />
</DialogContent>
</Dialog>

    </Box>

        ) : (<Box  sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
        {/* <Paper elevation={!border ? -1: paperElevation}> */}
            <div style={{ textAlign: 'center', padding: '20px' }}>
            {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Top 5 Most Vulnerable Hosts </Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
 <BarChart
                
                  width={border ? 1100 : 1760}
                     height={250}
                     data={data}
                    margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
                 >
                     <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="host" />
                    <YAxis />
                    <Tooltip/>
                     {/* <Legend /> */}
                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
                </BarChart>}
            </div>
        {/* </Paper> */}
    
        <Dialog 
onClose={handleClose} 
open={open} 
maxWidth="md" 
fullWidth 
PaperProps={{ style: { width: '95%', height: "90%" } }}
>
<DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
<DialogContent>
    <DataGrid 
          components={{
        Toolbar: GridToolbarContainer,
    }}
    componentsProps={{
        toolbar: {
            children: [
                <GridToolbarQuickFilter />,
                <GridToolbarExport />
                
            ],
        },
    }}
        rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
        columns={columns} 
        pageSize={50} 
        autoHeight={false} // Disable auto height for the grid
    />
</DialogContent>
</Dialog>

    </Box>

        )}
        </div>
        
    ) 
} 

else return(
        

        <div>
        {isMobile ? (<Box  sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
        {/* <Paper elevation={paperElevation}> */}
            <div style={{ textAlign: 'center', padding: '20px' }}>
               { godLoading ?       <Skeleton variant="rounded" height={150} />
:  <BarChart
                
                  width={350}
                     height={150}
                     data={data}
                    margin={{ top: 5, right: 50, left: -20, bottom: 5 }}
                 >
                     <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="host" />
                    <YAxis />
                    <Tooltip />
                     <Legend />
                    <Bar dataKey="count" fill="#8884d8" onClick={handleBarClick} cursor="pointer" />
                </BarChart>}
            </div>
        {/* </Paper> */}
    
        <Dialog 
onClose={handleClose} 
open={open} 
maxWidth="md" 
fullWidth 
PaperProps={{ style: { width: '95%' } }}
>
<DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
<DialogContent>
    <DataGrid 
          components={{
        Toolbar: GridToolbarContainer,
    }}
    componentsProps={{
        toolbar: {
            children: [
                <GridToolbarQuickFilter />,
                <GridToolbarExport />
            ],
        },
    }}
        rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
        columns={columns} 
        pageSize={50} 
        autoHeight
        
    />
</DialogContent>
</Dialog>

    </Box>

        ) : (<Box  sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
        {/* <Paper elevation={!border ? -1: paperElevation}> */}
            <div style={{ textAlign: 'center', padding: '20px' }}>
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
 <BarChart
                     width={border ? 880 : 880}
                     height={300}
                     data={data}
                     margin={{ top: 10, right: 5, left: 5, bottom: 10 }}
                 >
                     <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="host" style={{fontWeight: 'bold'}} />
                    <YAxis style={{fontWeight: 'bold'}}/>
                    <Tooltip />
                     {/* <Legend /> */}
                    <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor="pointer" />
                </BarChart>}
            </div>
        {/* </Paper> */}
    
        <Dialog 
onClose={handleClose} 
open={open} 
maxWidth="md" 
fullWidth 
PaperProps={{ style: { width: '95%', height: "90%" } }}
>
<DialogTitle>{selectedHost} Vulnerabilities</DialogTitle>
<DialogContent>
    <DataGrid 
          components={{
        Toolbar: GridToolbarContainer,
    }}
    componentsProps={{
        toolbar: {
            children: [
                <GridToolbarQuickFilter />,
                <GridToolbarExport />
                
            ],
        },
    }}
        rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
        columns={columns} 
        pageSize={50} 
        autoHeight={false} // Disable auto height for the grid
    />
</DialogContent>
</Dialog>

    </Box>

        )}
        </div>
        
    



    )

    
};

export default TopHostsBarChart;
