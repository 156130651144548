import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useAuth0 } from "@auth0/auth0-react";
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import ReportBuilder from "../reportbuilder";


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[300],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};



const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  } else 
  return (
    <Box 
      sx={{
        position: "fixed",
        zIndex: 1000,
    top: 0,
    left: 0,
    height: "100vh",
    overflowY: "auto",
    "& .pro-sidebar-inner": {
      background: `${colors.primary[600]} !important`,
    },
        "& .pro-sidebar-inner": {
          background: `${colors.primary[600]} !important`,
        
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#2C73A8 !important",
        },
        "& .pro-menu-item.active": {
          color: "#2C73A8 !important",
        },
        
      }}
    >
      <ProSidebar collapsed={isCollapsed} breakPoint="md">
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[300],
            }}
          >
          
            {!isCollapsed && (
              
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
             <Box display="flex" alignItems="center">
                <img
                  alt="Argus-Logo"
                  width="75px"
                  height="75px"
                  src={`../../assets/arg-color-white.png`}
                  style={{ cursor: "pointer", }}
                />
              </Box>
                
               <IconButton onClick={() => [setIsCollapsed(!isCollapsed), console.log(sessionStorage.getItem)]}>
                  <MenuOutlinedIcon />
                </IconButton>
                
              </Box>
            )}
          </MenuItem>
          
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
               
              </Box>
              <Box textAlign="center">
                <Typography variant="h5" color="#2C73A8">
                  {user['permissions/companies']}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}            

              />
               <Item
              title="Domain Dashboard"
              to="/domain-dashboard"
              icon={<DomainVerificationIcon />}
              selected={selected}
              setSelected={setSelected}            

              />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
            {!isCollapsed ? 'Argus Basic' : 'Basic' }

            </Typography>
            {/* <Item
              title="Critical Services"
              to="/critical"
              icon={<GradeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Domains"
              to="/domains"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
             <Item
              title="Digital Wallet"
              to="/wallet"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Document Center"
              to="/documents"
              icon={<InsertDriveFileOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Agent Monitor"
              to="/monitor"
              icon={<MonitorHeartOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
           
              {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {user['permissions/roles'] === 'admin' ? (
              <div>
              <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
            {!isCollapsed ? 'Argus Admin Tools' : 'Tools' }
            
            </Typography>
            {/* <Item
              title="Manage Clients"
              to="/clients"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Create Company"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />  
             <Item
              title="Upload Center"
              to="/upload"
              icon={<InsertDriveFileOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />    
            <Item
              title="Report Builder"
              to="/report-builder"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />   
            

              </div>

            ) : null}
     
           
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
