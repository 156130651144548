import React, {useState} from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


const VulnerabilityCount = ({ jsonData, border, data, godLoading, forReport }) => {
    
    const [PaperElevation, setPaperElevation] = useState(0);

    if(!forReport){ 
    return (
        <Box sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto' }}>
            <Card elevation={!border ? -1 : PaperElevation} sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto', backgroundColor: "#fcfcfc" }}>
                <CardContent>
                    {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> :<Typography variant="h5" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
                        Total Vulnerabilities
                    </Typography>}
                    {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 60, textAlign: "center", marginLeft: 3 }}/> :<Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                        {data}
                    </Typography>}
                </CardContent>
            </Card>
        </Box>
    );}
    else return (
        <Box sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto' }}>
        <Card elevation={!border ? -1 : PaperElevation} sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto', backgroundColor: "#ffffff" }}>
            <CardContent>
                {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> :<Typography variant="h5" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
                    Total Vulnerabilities
                </Typography>}
                {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 60, textAlign: "center", marginLeft: 3 }}/> :<Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                    {data}
                </Typography>}
            </CardContent>
        </Card>
    </Box>
    )
};

export default VulnerabilityCount;