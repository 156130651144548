import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import {useCookies} from 'react-cookie'
import { useAuth0 } from "@auth0/auth0-react";


axios.defaults.withCredentials = true;



function Copyright(props) {
  
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://msi.net">
        Argus100
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
  
}

const theme = createTheme();

export default function Login() {
// State---

// State End ----

// Good to handle submit
  const handleSubmit = (event) => {
    event.preventDefault();
    loginWithRedirect()
    // const data = new FormData(event.currentTarget);
    
    // axios.post('http://localhost:3001/login', {
    //     username: data.get('email'),
    //     password: data.get('password'),
    // })
    // .then((response)=>{
    //   if (response.status === 200){
    //     sessionStorage.setItem("p",response.data.userInfo.permissions)
    //     setAuth(true);
    //     return console.log({"msg":"Logged In, welcome to Argus100", "status": 200});
    //   } else return (console.log(JSON.stringify(response.data)) )
    // })
  };

  
  const { loginWithRedirect } = useAuth0();

  // return <button onClick={() => loginWithRedirect()}>Log In</button>;


 return(  
  <div>
    {loginWithRedirect()}
  </div>
  )
}