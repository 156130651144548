
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Paper,
  Divider,
  InputBase
} from "@mui/material";import React, { useState } from "react";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";
import Product from "../../components/Product";
import Grid from '@mui/material/Unstable_Grid2';
import { useAuth0 } from "@auth0/auth0-react";
import LoginPopout from "../../components/LoginPopout";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";
import DomainInfo from "../../components/DomainInfo";
import VtInfo from "../../components/VtInfo";
import SmallLoadingComp from "../../components/SmallLoading";
import MonitorAgents from "../../components/MonitorAgent";


const Payment = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [company, setCompany] = useState("");
  const [validation, setValidation] = useState(false);
  const [companyExists, setCompanyExists] = useState(false);
  const [firstLogin, setFirstLogin] = React.useState(true);


  const handleFirstLoginChange = (value) => {
      setFirstLogin(value);
    };
  function handleChange(event) { setCompany(event.target.value);  }

//   function handleSubmit(event) {  event.preventDefault(); 
//     var userName = user.email
//     var data = {company: company}
    
//     var options = {
//       method: 'POST',
//       url: process.env.REACT_APP_APIURL+"/api/create/company/"+user.sub,
//       headers: {'content-type': 'application/json'},
//       data: data
//     };
    
//     axios.request(options).then(function (response) {
//       console.log(response.data);
//       if(response.data.msg === "company created"){
//       return window.location.reload();     
//  }
//  if(response.data.msg === "Company already exists"){setCompanyExists(true);}
//     }).catch(function (error) {
//       console.error(error);
//     });

//   }
//   function preventDefault(event) {  event.preventDefault();  }

  if (isLoading) {
    return <div>Loading ...</div>;} else {
 return (
    
    <ColorModeContext.Provider value={colorMode}>
        {console.log(user['permissions/companies'])}

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <Sidebar isSidebar={isSidebar} /> 
          <main className="content">            
            <Topbar setIsSidebar={setIsSidebar} />
            <Box m="20px">
    </Box>
    <Box sx={{flexGrow: 1, paddingTop: "5%"}}>
    <Typography variant="h4" align="center"> Are you looking for more detalis? <br></br> Please sign up for our Argus100 Basic plan! <br></br> You'll get more insight on your domain as well as an Argus100 expert to help setup the Argus advanced network security analasys.</Typography>
    <Product />



    <Box width={"100%"} paddingLeft={"10px"}>
    <DomainInfo/>
    </Box>
    <Box paddingTop={"5px"} marginBottom={"10px"} paddingLeft={"10px"}>
    <VtInfo/>

    </Box>
    <Box paddingTop={"5px"} marginBottom={"35px"} paddingLeft={"10px"}>
    <MonitorAgents firstLogin={firstLogin} onFirstLoginChange={handleFirstLoginChange}/>
    </Box>
 
          </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>


      
  );
};
};
export default Payment;
