import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from 'react-responsive';
import SmallLoadingComp from './SmallLoading';


function DomainInfo() {
  const [data, setData] = useState("");
  const [domain, setDomain] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [admin, setAdmin] = useState("");
  const [tech, setTech] = useState("");
  const { user, isAuthenticated, isLoading } = useAuth0();
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
  const [errcon, setErrcon] = useState();

  const domainRows = [
    { label: 'Domain Name', value: domain.domainName },
    { label: 'Registry Domain ID', value: domain.registryDomainId },
    { label: 'Registrar WHOIS Server', value: domain.registrarWhoisServer },
    { label: 'Registrar URL', value: domain.registrarUrl },
    { label: 'Updated Date', value: domain.updatedDate },
    { label: 'Registrar', value: domain.registrar },
    { label: 'Registrar Abuse Contact Email', value: domain.registrarAbuseContactEmail },
    { label: 'Registrar Abuse Contact Phone', value: domain.registrarAbuseContactPhone },
    { label: 'Name Server', value: domain.nameServer },
  ];

  const registrantRows = [
    { label: 'Registrant Name', value: registrant.registrantName },
    { label: 'Registrant Organization', value: registrant.registrantOrganization },
    { label: 'Registrant Street', value: registrant.registrantStreet },
    { label: 'Registrant City', value: registrant.registrantCity },
    { label: 'Registrant State/Province', value: registrant.registrantStateProvince },
    { label: 'Registrant Postal Code', value: registrant.registrantPostalCode },
    { label: 'Registrant Country', value: registrant.registrantCountry },
    { label: 'Registrant Phone', value: registrant.registrantPhone },
    { label: 'Registrant Phone Ext', value: registrant.registrantPhoneExt },
    { label: 'Registrant Fax Ext', value: registrant.registrantFaxExt },
  ];

  const adminRows = [
    { label: 'Admin Name', value: admin.adminName },
    { label: 'Admin Organization', value: admin.adminOrganization },
    { label: 'Admin Street', value: admin.adminStreet },
    { label: 'Admin City', value: admin.adminCity },
    { label: 'Admin State/Province', value: admin.adminStateProvince },
    { label: 'Admin Postal Code', value: admin.adminPostalCode },
    { label: 'Admin Country', value: admin.adminCountry },
    { label: 'Admin Phone', value: admin.adminPhone },
    { label: 'Admin Phone Ext', value: admin.adminPhoneExt },
    { label: 'Admin Fax Ext', value: admin.adminFaxExt },
  ];

  const techRows = [
    { label: 'Tech Name', value: tech.techName },
    { label: 'Tech Organization', value: tech.techOrganization },
    { label: 'Tech Street', value: tech.techStreet },
    { label: 'Tech City', value: tech.techCity },
    { label: 'Tech State/Province', value: tech.techStateProvince },
    { label: 'Tech Postal Code', value: tech.techPostalCode },
    { label: 'Tech Country', value: tech.techCountry },
    { label: 'Tech Phone', value: tech.techPhone },
    { label: 'Tech Phone Ext', value: tech.techPhoneExt },
    { label: 'Tech Fax Ext', value: tech.techFaxExt },
  ];

  useEffect(() => {
    console.log(user);
    async function fetchData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/domain/${user["permissions/domain"]}`);
        console.log(response.status);
        setDomain(response.data.domain);
        setRegistrant(response.data.registrant);
        setAdmin(response.data.admin);
        setTech(response.data.tech);
        console.log(domain);
      } catch (error) {
        setDomain(false);
        setRegistrant(false);
        setAdmin(false);
        setTech(false);
        setErrcon(true);
      }
    }

    fetchData();
  }, [user]);

  if (isLoading) {
    return <SmallLoadingComp />;
  } else {
    return (
      <Box>
        {errcon ? (
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Looks like your domain has some protection behind it! <br></br> Dont worry, we'll crack it! Check back later for the results.
          </Typography>
        ) : null}
        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Administrator Details
          </Typography>
          <TableContainer >
            <Table size="small" aria-label="domain information table">
              <TableBody>
                {!admin ? (
                  <SmallLoadingComp />
                ) : (
                  adminRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Registrant Details
          </Typography>
          <TableContainer >
            <Table size="small" aria-label="registrant information table">
              <TableBody>
                {!registrant ? (
                  <SmallLoadingComp />
                ) : (
                  registrantRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Domain Details
          </Typography>
          <TableContainer >
            <Table size="small" aria-label="admin information table">
              <TableBody>
                {!domain ? (
                  <SmallLoadingComp />
                ) : (
                  domainRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ padding: '10px' }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Tech Details
          </Typography>
          <TableContainer >
            <Table size="small" aria-label="tech information table">
              <TableBody>
                {!tech ? (
                  <SmallLoadingComp />
                ) : (
                  techRows.map((row) => (
                    <TableRow key={row.label}>
                      <TableCell>{row.label}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
}

export default DomainInfo;