import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import { Box, Paper, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library
import Skeleton from '@mui/material/Skeleton';

import axios from 'axios';

const OperatingSystemsBarChart = ({ jsonData, border, selectedDate, company, data, godLoading, forReport }) => {
    
    const [chartData, setChartData] = useState([]);
    const [paperElevation, setPaperElevation] = useState(0);
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
    const { user } = useAuth0();
    const [top5Data, setTop5Data] = useState([]);

    useEffect(() => {
        setChartData(data);
         // Sort the chartData array by the 'count' field in descending order
         if (data && forReport) {
            const sortedData = data.sort((a, b) => b.count - a.count);
            // Keep only the top 5 objects in the array
            const top5 = sortedData.slice(0, 7);
            setTop5Data(top5);}

    }, [data]);
    
    
    const formatTickItem = (tickItem) => {
        if(isMobile){
            if (tickItem.length > 10 ) {
                return `${tickItem.substring(0, 10)}...`;
            } else return tickItem
        }else {
        if (tickItem.length > 25 ) {
                return `${tickItem.substring(0, 24)}...`;
            } else return tickItem
        }
    };
    const [open, setOpen] = useState(false);
    const [selectedOS, setSelectedOS] = useState("");
    const [osDetails, setOSDetails] = useState([]);

    const handleBarClick = async (data) => {
        var response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${selectedDate}/${data.osName}/hosts`)
        setOSDetails(response.data.details.map(row => ({ ...row, id: uuidv4() }))); // Generate unique id for each row
        setSelectedOS(response.data.osName);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
   

    if (!forReport) return (
         
        <div>
    
            {isMobile ? (
                <Box sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                        <div style={{ textAlign: 'center', padding: '20px', paddingLeft: '0px' }}>
                        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Operating Systems</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
                            <BarChart width={320} height={150} data={chartData} margin={{bottom: 42 }} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="osName" tickFormatter={formatTickItem} angle={-45} textAnchor="end" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                            </BarChart>}
                        </div>
                </Box>
            ) : (
                <Box sx={border ? { width: 1220, height: 360 } : { width: 1760, height: 360 } } onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                        <div style={{ textAlign: 'center', padding: '20px', paddingBottom: '5px' }}>
                        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Operating Systems</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
                            <BarChart width={border ? 1190 : 1760} height={350} data={chartData} margin={{ top: 1, right: 1, left: 1, bottom: 125 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="osName" tickFormatter={formatTickItem} angle={-45} textAnchor="end" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor={"pointer"}/>
                            </BarChart>}
                        </div>
                </Box>
            )}
            <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth PaperProps={{ style: { width: '35%'} } }>
                <DialogTitle>{selectedOS} Details</DialogTitle>
                <DialogContent>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={osDetails}
                            columns={[
                                { field: 'ip', headerName: 'IP', width: 150 },
                                { field: 'hostname', headerName: 'Hostname', width: "290", resizable: true },
                            ]}
                            components={{
                                        Toolbar: GridToolbarContainer,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            children: [
                                                <GridToolbarQuickFilter />,
                                                <GridToolbarExport />
                                            ],
                                        },
                                    }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )

if (forReport) return (
    <div>
        {isMobile ? (
            <Box sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px', paddingLeft: '0px' }}>
                    {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> : <Typography variant='h3' fontWeight={"bold"} >Operating Systems</Typography>}
                    {godLoading ? <Skeleton variant="rounded" height={250} /> :
                        <BarChart width={320} height={150} data={top5Data} margin={{ bottom: 42 }} >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="osName" tickFormatter={formatTickItem} angle={-45} textAnchor="end" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} />
                        </BarChart>}
                </div>
            </Box>
        ) : (
            <Box sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)}>
                <div style={{ textAlign: 'center', padding: '20px', paddingBottom: '5px' }}>
                    {godLoading ? <Skeleton variant="rounded" height={250} /> :
                        <BarChart width={border ? 880 : 880} height={350} data={top5Data} margin={{ top: 1, right: 1, left: 1, bottom: 125 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="osName" tickFormatter={formatTickItem} angle={-35} textAnchor="end" style={{ fontWeight: 'bold' }} />
                            <YAxis style={{ fontWeight: 'bold' }} />
                            <Tooltip />
                            <Bar dataKey="count" fill="#1491CB" onClick={handleBarClick} cursor={"pointer"} />
                        </BarChart>}
                </div>
            </Box>
        )}
        <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth PaperProps={{ style: { width: '35%' } }}>
            <DialogTitle>{selectedOS} Details</DialogTitle>
            <DialogContent>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={osDetails}
                        columns={[
                            { field: 'ip', headerName: 'IP', width: 150 },
                            { field: 'hostname', headerName: 'Hostname', width: "290", resizable: true },
                        ]}
                        components={{
                            Toolbar: GridToolbarContainer,
                        }}
                        componentsProps={{
                            toolbar: {
                                children: [
                                    <GridToolbarQuickFilter />,
                                    <GridToolbarExport />
                                ],
                            },
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    </div>
)
};

export default OperatingSystemsBarChart;
