import React, { useState, useEffect, useRef } from "react";
import { IconButton, Paper, Typography, Tooltip, Box, useTheme } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ColorModeContext, tokens } from "../theme";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function DateSelector({ availableDates, setSelectedDate, selectedDate, setAvailableDates }) {
  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate);
  const [tempAvailableDates, setTempAvailableDates] = useState(availableDates);
  const timerRef = useRef(null);
  const currentIndex = tempAvailableDates.indexOf(tempSelectedDate);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const path = location.pathname;

  // const { user, isAuthenticated, isLoading } = useAuth0();
  // const paid = user["permissions/paid"];

  const selectedDateDump = () => {
    if (tempAvailableDates && tempAvailableDates.length === 0) {
      console.log("array empty already");
    } else {
      console.log("Dumping selected Date");
      setTempSelectedDate([]);
      setTempAvailableDates([]);
    }
  };

  const goForward = () => {
    if (currentIndex > 0) {
      setTempSelectedDate(tempAvailableDates[currentIndex - 1]);
    }
  };

  const goBackward = () => {
    if (currentIndex < tempAvailableDates.length - 1) {
      setTempSelectedDate(tempAvailableDates[currentIndex + 1]);
    }
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setSelectedDate(tempSelectedDate);
      setAvailableDates(tempAvailableDates);
    }, 900); // Adjust the time period as needed
  }, [tempSelectedDate, tempAvailableDates]);

  useEffect(() => {
    if (tempAvailableDates !== availableDates) {
      setTempAvailableDates(availableDates);
    }
    if (tempSelectedDate !== selectedDate) {
      setTempSelectedDate(selectedDate);
    }
  }, [availableDates]);

  if (path === "/" || path === "/dashboard" || path === "/dashboard/") {
    if (tempAvailableDates === undefined) {
      return null;
    } else
      return (
        <Box width={"300px"} display={"flex"} alignItems="center" justifyContent="center">
          <Tooltip title="Previous Date">
            <IconButton
              onClick={goBackward}
              disabled={currentIndex === tempAvailableDates.length - 1}
              style={{ color: colors.primary[1000], visibility: currentIndex === tempAvailableDates.length - 1 ? 'hidden' : 'visible' }}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </Tooltip>
          <Typography
            variant="h4"
            style={{
              margin: '0 5px',
              fontWeight: 'bold',
              color: colors.primary[1000]
            }}
          >
            {tempSelectedDate && "Date as of: " + tempSelectedDate.slice(0, -4)}
          </Typography>
          <Tooltip title="Next Date">
            <IconButton
              onClick={goForward}
              disabled={currentIndex === 0}
              style={{ color: colors.primary[1000], visibility: currentIndex === 0 ? 'hidden' : 'visible' }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
  } else {
    if (!tempAvailableDates) {
      return null;
    } else {
      selectedDateDump();
      return null;
    }
  }
}

export default DateSelector;