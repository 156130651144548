import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Typography, Box } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import SmallLoadingComp from "./SmallLoading";

const MonitorAgents = ({ firstLogin, onFirstLoginChange }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [gridData, setGridData] = React.useState(false);
  const [programaticData, setProgramaticData] = React.useState(false);


  var postData = {
    hostname: user["permissions/domain"],
    company: user["permissions/companies"],
    agents: true,
    user: user.sub,
  };

  const columns = [
    { field: "Name", headerName: "Name", flex: 1 },
    { field: "Hostname", headerName: "Hostname", flex: 1 },
  ];

  if (gridData) {
    // If gridData is true, add the third column
    columns.push({
      field: "Available",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => {
        const isAvailable = params.row.Ports[0].Available;
        return isAvailable ? "Online" : "Offline";
      },
      renderCell: (params) => (
        <div style={{ color: params.value === "Online" ? "green" : "red" }}>
          {params.value}
        </div>
      ),
    });
  }

  const addUniqueId = (data) => {
    return data.map((row, index) => ({ id: index + 1, ...row }));
  };

  React.useEffect(() => {
    
    if(!user["permissions/agents"] || user["permissions/agents"] === "false"){
        
    axios
      .get(process.env.REACT_APP_APIURL+"/api/at/"+ user["permissions/companies"]+"/tcpdevices")
      .then((response) => {
        if(response.status === 201){
            setGridData(false);
            console.log("new company, reload needed.")
        }
        if (response.status === 200) {
              const gridData = addUniqueId(response.data);
              setGridData(gridData);
          
        }
      })
      .catch((error) => {
        if (error.response.status === 428) {
            axios
              .post(
                process.env.REACT_APP_APIURL + "/api/at/specific/devices",
                postData
              )
              .then((response) => {
                if(response.status === 200){
                    axios
                    .get(process.env.REACT_APP_APIURL+"/api/at/"+ user["permissions/companies"]+"/tcpdevices")
                    .then((response) => {
                      if(response.status === 201){
                          setGridData(false);
                          console.log("new company, reload needed.")
                      }
                      if (response.status === 200) {
                            const gridData = addUniqueId(response.data);
                            setGridData(gridData);
                        
                      }
                    })
                    .catch((error) => {
                        console.log(error.response)
                    });

                }

              })
              .catch((error) => {
                console.error("Error fetching agent data: ", error);
              });
      } 
      if(error.response.status === 404){
        axios
        .post(
          process.env.REACT_APP_APIURL + "/api/at/specific/devices",
          postData
        )
        .then((response) => {
          if(response.status === 201){

             axios
        .get(process.env.REACT_APP_APIURL+"/api/at/"+ user["permissions/companies"]+"/tcpdevices")
        .then((response) => {
          if(response.status === 201){
              setGridData(false);
              console.log("new company, reload needed.")
          }
          if (response.status === 200) {
                const gridData = addUniqueId(response.data);
                setGridData(gridData);
            
          }
        })
        .catch((error) => {
            console.log(error.response)
        });
          }
        })
        .catch((error) => {
          console.error("Error fetching agent data: ", error);
        });

      }

        console.error("Error fetching agent data: ", error);
      });
    }else{
        axios
        .get(process.env.REACT_APP_APIURL+"/api/at/"+ user["permissions/companies"]+"/tcpdevices")
        .then((response) => {
          if(response.status === 201){
              setGridData(false);
              console.log("new company, reload needed.")
          }
          if (response.status === 200) {
                const gridData = addUniqueId(response.data);
                setGridData(gridData);
            
          }
        })
        .catch((error) => {
            console.log(error.response)
        });
    }
  }, [isLoading]);

  return (
    <Box
      style={{ height: 410, width: "100%", padding: "1rem", marginTop: "1rem" }}
    >
      <Typography variant="h6" style={{ marginBottom: "1rem" }}>
        Monitored Agents
      </Typography>
      {gridData ? (
        <DataGrid
          rows={gridData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      ) : (
        <SmallLoadingComp />
      )}
    </Box>
  );
};

export default MonitorAgents;
