import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Dialog, DialogTitle, DialogContent, List, ListItem, Tooltip} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';


const UniqueHostCount = ({ jsonData, border, data, date, company, godLoading, forReport }) => {

    // Create a data structure to keep track of vulnerabilities count for each host


    const [paperElevation, setPaperElevation] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [hostVulnerabilities, setHostVulnerabilities] = useState([]);

    const handleOpen = async () => {
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/hosts`);
        console.log(`${process.env.REACT_APP_APIURL}/api/${company}/visualizations/${date}/vulns/${data.host}`)
        setHostVulnerabilities(response.data);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

   

    const columns = [
        // { field: 'id', headerName: 'ID', width: '50' },
        { field: 'host', headerName: 'Host', width: '100' },
        { field: 'vulnerabilityCount', headerName: 'Total Vulns', width: '100'},
        { field: 'criticalCount', headerName: 'Total Critical', width: '100'},
        { field: 'highCount', headerName: 'Total High', width: '100'},
        { field: 'mediumCount', headerName: 'Total Medium', width: '100'},
        { field: 'lowCount', headerName: 'Total Low', width: '100'},
        // ... add more columns as per your data structure
    ];

    if (!forReport) {
    return (
        <div>
                {console.log(forReport)}

            <Box sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', cursor: 'pointer' }} 
                 onMouseEnter={() => setPaperElevation(5)} 
                 onMouseLeave={() => setPaperElevation(0)} 
                 onClick={handleOpen}
            >
                <Card elevation={!border ? -1 : paperElevation} sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', backgroundColor: "#fcfcfc" }}>
                    <CardContent>
                {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> : <Typography variant="h5" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
                    Unique <br></br>Hosts
                </Typography>}
                        <Tooltip title="Click to view hosts and their vulnerabilities">
                            {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 60, textAlign: "center", marginLeft: 3 }}/>  : <Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                                {data}
                            </Typography>}
                        </Tooltip>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={openDialog} onClose={handleClose}
                maxWidth="md" 
                fullWidth 
                PaperProps={{ style: { width: '50%'} }}
            >
                <Box sx={{ width: '100%' }}>
                    <DialogTitle>Unique Hosts and Their Vulnerabilities</DialogTitle>
                    <DialogContent>
                        <Box sx={{ height: 600, overflow: 'auto' }}>
                            <div style={{ height: 600 }}>
                                <DataGrid 
                                    components={{
                                        Toolbar: GridToolbarContainer,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            children: [
                                                <GridToolbarQuickFilter />,
                                                <GridToolbarExport />
                                            ],
                                        },
                                    }}
                                    rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
                                    columns={columns} 
                                    pageSize={25} 
                                    autoHeight={false} // Disable auto height for the grid
                                />
                            </div>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        </div>
    );}


    
    else return ( <div>
        {console.log(forReport)}

    <Box sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', cursor: 'pointer' }} 
         onMouseEnter={() => setPaperElevation(5)} 
         onMouseLeave={() => setPaperElevation(0)} 
         onClick={handleOpen}
    >
        <Card elevation={!border ? -1 : paperElevation} sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', backgroundColor: "#ffffff" }}>
            <CardContent>
        {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 80, textAlign: "center", marginLeft: 2 }}/> : <Typography variant="h5" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
            Unique <br></br>Hosts
        </Typography>}
                <Tooltip title="Click to view hosts and their vulnerabilities">
                    {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 60, textAlign: "center", marginLeft: 3 }}/>  : <Typography variant="h2" component="div" sx={{ textAlign: 'center' }}>
                        {data}
                    </Typography>}
                </Tooltip>
            </CardContent>
        </Card>
    </Box>

    <Dialog open={openDialog} onClose={handleClose}
        maxWidth="md" 
        fullWidth 
        PaperProps={{ style: { width: '50%'} }}
    >
        <Box sx={{ width: '100%' }}>
            <DialogTitle>Unique Hosts and Their Vulnerabilities</DialogTitle>
            <DialogContent>
                <Box sx={{ height: 600, overflow: 'auto' }}>
                    <div style={{ height: 600 }}>
                        <DataGrid 
                            components={{
                                Toolbar: GridToolbarContainer,
                            }}
                            componentsProps={{
                                toolbar: {
                                    children: [
                                        <GridToolbarQuickFilter />,
                                        <GridToolbarExport />
                                    ],
                                },
                            }}
                            rows={hostVulnerabilities.map((vuln, index) => ({ ...vuln, id: index }))} 
                            columns={columns} 
                            pageSize={25} 
                            autoHeight={false} // Disable auto height for the grid
                        />
                    </div>
                </Box>
            </DialogContent>
        </Box>
    </Dialog>
</div>)
};

export default UniqueHostCount;
