

import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import { Box, Paper, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from 'react-responsive';
import Skeleton from '@mui/material/Skeleton';


const ProtocolPieChart = (props) => {
    const { border, data, godLoading } = props;
    const { user } = useAuth0();
    const [chartData, setChartData] = useState([]);
    const [paperElevation, setPaperElevation] = useState(3);
    const isMobile = useMediaQuery({ query: '(max-width: 900px)' });
    const colors = {
        tcp: '#ff7f0e',
        udp: '#1491CB',
        icmp: '#8884d8',
      };
    useEffect(() => {
  
setChartData(data)
    }, [user, data]);
    if (data === undefined) { return null } else return (
        <Box sx={border ? { width: isMobile ? 350 : 580, height: isMobile ? 150 : 300 } : { width: isMobile ? 350 : 880, height: isMobile ? 150 : 300 } } onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: "275px", marginLeft: "25%" }}/> : <Typography variant='h3' fontWeight={"bold"} textAlign={"center"}>Distribution by Protocol</Typography>}
                   { godLoading ?      <Skeleton variant="circular" height={ isMobile ? 100 : 250} width={ isMobile ? 100 : 250} sx={{marginLeft: isMobile ? 10 : 20}}/>
                    :
                    <PieChart width={isMobile ? 350 : 580} height={isMobile ? 150 : 250}>
                        <Pie
                            data={chartData}
                            dataKey="value"
                            nameKey="name"
                            outerRadius={isMobile ? 70 : 110}
                            fill="#8884d8"
                            label
                            minAngle={10}
                        >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[entry.name]} />)
                            }
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>}
                </div>
        </Box>
    );
};

export default ProtocolPieChart;

