import { React, useEffect, useState, useCallback, setState } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Stack,
  Paper,
  Divider,
  InputBase
} from "@mui/material";
import {
  DataGrid,
  GridRowModes,
  GridToolbar,
  GridActionsCellItem,
  NoResultsOverlay,
  NoRowsOverlay,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCredentials } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { ForkRight, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { color } from "@mui/system";
import WalletEntry from "../../components/WalletEntry";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Input from '@mui/material/Input';
import { preventDefault } from "@fullcalendar/core";
import CryptoJS from "crypto-js";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import SaveIcon from '@mui/icons-material/Save';
import { Link } from "react-router-dom";


const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userCompany = user["permissions/companies"];

  const [showPassword, setShowPassword] = useState(false);
  const [secretCode, setSecretCode] = useState(null);
  const [secretCodeTemp, setSecretCodeTemp] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 

  const [data, setData] = useState([]);
  const [rows, setRows] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);
  const [saveState, setSaveState] = useState(false);
  const [errorCatch, setErrorCatch] = useState(false);
  const [failCounter, setFailCounter] = useState(0);
  const [failInfo, setFailInfo] = useState(false);

  function dataTableLoading(){
    if(data.length === null || data.length === 0){
      return false
    }else if (!data.length){
      return true
    }else{
      return false
    }
  }

const handleSubmit = (event) => {
  fetch(process.env.REACT_APP_APIURL + "/api/" + userCompany + "/encwallet")
      .then((response) => response.json())
      .then((json) => {
        setSecretCode(secretCodeTemp);
        var bytes  = CryptoJS.AES.decrypt(json, secretCodeTemp);
        if(bytes) {
          try {
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            setData(decryptedData);
            setErrorCatch(false)
            setFailCounter(0)
            console.log("correct passkey, proceed to decrypt")
          } catch(e) {
          console.log("Incorrect Passkey")
          setFailCounter(failCounter + 1)
          setFailInfo(true)
          setErrorCatch(true)}
                  }
      });
}

  // useEffect(() => {
  //   fetch(process.env.REACT_APP_APIURL + "/api/" + userCompany + "/wallet")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       var bytes  = CryptoJS.AES.decrypt(json, secretCode)
  //       setData(bytes.toString(CryptoJS.enc.Utf8));
  //     });
  // }, []);

  const deleteRow = useCallback(
    (id) => () => {
      var array = [...data]; // make a separate copy of the array
      const currentIndex = array.findIndex(x => x._id === id);
      if (currentIndex !== -1) {
        array.splice(currentIndex, 1);
        setData(array);
        setSaveState(true)
      }
    },
    [data]

  );

  

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow};

    const currentIndex = data.findIndex((data) => data._id === updatedRow._id);
    const updatedData = {...data[currentIndex], ...updatedRow}
    const newData = [...data];
    newData[currentIndex] = updatedData;
    setData(newData)
    setSaveState(true)
    return updatedRow;
};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => { 
    setErrorCatch(false);
    setSecretCode(secretCodeTemp);
    setFailCounter(0)
    axios
      .post(
        process.env.REACT_APP_APIURL+"/api/"+user["permissions/companies"]+"/encwallet/delete")
      .then((response) => { console.log(response.json)})
      .then()

  }



  const handleSave = () => {

    var bytes  = CryptoJS.AES.encrypt(JSON.stringify(data), secretCode).toString();
    console.log(bytes);
    var encWallet = { encWallet: bytes}
     axios.post(process.env.REACT_APP_APIURL+"/api/"+user["permissions/companies"]+'/encwallet/create', encWallet)
        .then(setSaveState(false))
        };

        function CustomToolbar() {
          return (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
              {saveState ? <Button title="There is data that needs to be saved!" onClick={handleSave} sx={{paddingTop: 0.4, paddingBottom: 0.4, paddingLeft: 0.5, paddingRight: 0.5}}>
              <SaveIcon color="success" className="saveIcon"/>
              </Button> : null

              }
             
            </GridToolbarContainer>
          );
        }

  const open = Boolean(anchorEl);
  const id = open ? "New Wallet Entry" : undefined;

  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
      editable: true
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      editable: true
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1,
      editable: true,
      renderCell: rowData => <p>{rowData.value.split('').map(() => '*')}</p>,
      editComponent: props => (
        <TextField
            type="password"
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
        />)
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      editable: true
    },
    {
      field: "resource",
      headerName: "Resouce",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        if(params.value.includes("https://") || params.value.includes("http://")){
          return <a href={params.value}>{params.value}</a>
        } else {
          return <a href={"https://"+params.value}>{params.value}</a>
        }
        
      }    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={deleteRow(params.id)}
        />,
      ],
    },
  ];

  if (isLoading) {
    return <div>Loading ...</div>;
  } else
    return !secretCode || errorCatch ? (
      // create form to enter secret code
      <div>
      <Box m="20px">
      <Header
            title="DIGITAL WALLET"
            subtitle="Company Info and Credentials"
          />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "60vh" }}
      >
        <Grid item xs={3}>
        <Paper
        elevation={5}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,  }}
      onSubmit={preventDefault}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Create or enter your secret..."
        inputProps={{ 'aria-label': 'Secret' }}
        type="password"
        onChange={(e) => { setSecretCodeTemp(e.target.value); setFailInfo(false)}}
        onKeyPress={e => e.key === 'Enter' && handleSubmit()}
        
              />
      
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
      onClick={() => handleSubmit()}
     >
        <ArrowForwardIcon />
      </IconButton>
    </Paper>
    {failInfo ?  <Typography className="failText" sx={{paddingTop: 1, textAlign: "center"}}>
          Incorrect secret. Please try again.
        </Typography> : null
    }
   

        </Grid>


        {errorCatch && failCounter > 2 ? 
        <Grid item xs={3}>
        <Typography sx={{paddingTop: 10, textAlign: "center"}}>
          Forgot your secret? You can set a new one below...
        </Typography>
        <Paper
        elevation={5}
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      onSubmit={preventDefault}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Create a new secret"
        inputProps={{ 'aria-label': 'Secret' }}
        type="password"
        onChange={(e) =>  setSecretCodeTemp(e.target.value)}
        onKeyPress={e => e.key === 'Enter' && handleDelete()}
              />
      
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
      onClick={() => handleDelete()}
     >
        <ArrowForwardIcon />
      </IconButton>
      
    </Paper>
    <Typography sx={{paddingTop: 1, textAlign: "center"}}>
          WARNING: This will delete all the data in your current wallet!
        </Typography>
        </Grid>
        : null }
      </Grid>
      </Box>
      </div>
    ) : (
      <Box m="20px">
        <Header
          title="DIGITAL WALLET"
          subtitle="Company Info and Credentials"
        />

        <div>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            sx={{ backgroundColor: colors.blueAccent[400] }}
          >
            Add New Entry
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <WalletEntry data={data} setData={setData} close={setAnchorEl} keyInfo={secretCode} setSave={setSaveState} />
          </Popover>
        </div>
        <Box
          m="40px 0 0 0"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[200],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[400],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[400],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            editMode="row"
            loading={dataTableLoading()}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No rows in DataGrid
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Local filter returns no result
                </Stack>
              ),
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            getRowId={(row) => row._id}
            // onRowEditCommit={(row) => stoppedEditing(row)}
            processRowUpdate={processRowUpdate}
          />
        </Box>
      </Box>
    );
};

export default Contacts;
