import React from 'react';
import { Button } from "@mui/material";

export default function PairO365Button({ companyId }) {

  const handlePair = () => {
    const oauthUrl = `${process.env.REACT_APP_APIURL}/api/oauth/login/${companyId}`;
    window.open(oauthUrl, "Pair O365", "width=600,height=700");
  };

return (
    <div>
        <Button
            onClick={handlePair}
            variant="outlined"
            sx={{
                borderColor: 'green',
                color: 'green',
                '&:hover': {
                    borderColor: 'green'
                }
            }}
        >
            Pair with O365
        </Button>
    </div>
);
}
