// import {React, useEffect, useState} from 'react';
// import { Chart, Layer, Ticks, Areas, Transform } from 'rumble-charts';
// import axios from 'axios';
// import { useAuth0 } from "@auth0/auth0-react";







// const AreaChart = () => {
//     const {user, isAuthenticated, isLoading } = useAuth0();
//     const [seriesData, setSeries] = useState();
//     const [cumulativeCount, setCount] = useState();
//     // const [jsonData, setJsonData] = useState([])

//     useEffect(() => {
//       axios.get(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/data')
//       .then(json => {
//         const jsonData = json.data
//         const filteredData = jsonData.filter(record => record['Plugin Publication Date']);

// // 2. Group by 'Plugin Publication Date' to get the count for each date
// let dataByDate = {};
// filteredData.forEach(record => {
//     const date = record['Plugin Publication Date'];
//     if (!dataByDate[date]) {
//         dataByDate[date] = 0;
//     }
//     dataByDate[date] += 1;
// });

// // 3. Convert to an array and sort by date
// const sortedData = Object.keys(dataByDate).sort().map(date => dataByDate[date]);

// // 4. Create a cumulative array
// let cumulativeCount = 0;
// const cumulativeData = sortedData.map(count => {
//     cumulativeCount += count;
//     return cumulativeCount;
// });
// setSeries([{ data: cumulativeData }])
    
    
//       })},[])


//     return(
//     <div>
//         <h2>Cumulative Vulnerabilities Over Time</h2>
//         <Chart width={600} height={300} series={seriesData} minY={0}>
//             <Layer width="90%" height="80%" position="middle center">
//                 <Ticks
//                     axis="y"
//                     lineLength="100%"
//                     lineVisible={true}
//                     lineStyle={{ stroke: '#e5e5e5' }}
//                     labelStyle={{ textAnchor: 'end', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
//                     labelAttributes={{ x: -5 }}
//                 />
//                 <Ticks
//                     axis="x"
//                     labelFormat={index => new Date(index).toLocaleDateString()}
//                     labelStyle={{ textAnchor: 'middle', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
//                 />
//                 <Transform method={['stack']}>
//                     <Areas />
//                 </Transform>
//             </Layer>
//         </Chart>
//     </div>)
// };

// export default AreaChart;


import React, { useEffect, useState } from 'react';
import { Chart, Layer, Ticks, Areas, Transform } from 'rumble-charts';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const AreaChart = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [seriesData, setSeries] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_APIURL + '/api/' + user["permissions/companies"] + '/data');
                const jsonData = response.data;
                
                const filteredData = jsonData.filter(record => record['Plugin Publication Date']);

                let dataByDate = {};
                filteredData.forEach(record => {
                    const date = new Date(record['Plugin Publication Date']).toISOString().split('T')[0];  // Normalize the date format
                    if (!dataByDate[date]) {
                        dataByDate[date] = 0;
                    }
                    dataByDate[date] += 1;
                });

                const sortedData = Object.keys(dataByDate).sort().map(date => dataByDate[date]);
                let cumulativeCount = 0;
                const cumulativeData = sortedData.map(count => {
                    cumulativeCount += count;
                    return cumulativeCount;
                });

                setSeries([{ data: cumulativeData }]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [user]);

    return (
        <div>
            <h2>Cumulative Vulnerabilities Over Time</h2>
            <Chart width={600} height={300} series={seriesData} minY={0}>
                <Layer width="90%" height="80%" position="middle center">
                    <Ticks
                        axis="y"
                        lineLength="100%"
                        lineVisible={true}
                        lineStyle={{ stroke: '#e5e5e5' }}
                        labelStyle={{ textAnchor: 'end', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
                        labelAttributes={{ x: -5 }}
                    />
                    <Ticks
                        axis="x"
                        labelFormat={index => new Date(index).toLocaleDateString()}
                        labelStyle={{ textAnchor: 'middle', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
                    />
                    <Transform method={['stack']}>
                        <Areas />
                    </Transform>
                </Layer>
            </Chart>
        </div>
    );
};

export default AreaChart;
