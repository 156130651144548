import React from 'react';
import './Dashboard.css';

const AIDashboard = () => {
  return (
    <div className="dashboard">
      <div className="header">
        <h1>Monitor health of your business</h1>
        <p>Control and analyze your data in the easiest way</p>
      </div>
      
      <div className="metrics">
        <div className="metric">
          <div className="icon views"></div>
          <h2>Views</h2>
          <p>31</p>
          <span>+3 last day</span>
        </div>
        
        <div className="metric">
          <div className="icon clients"></div>
          <h2>Clients</h2>
          <p>63</p>
          <span>+1 last day</span>
        </div>
        
        <div className="metric">
          <div className="icon purchases"></div>
          <h2>Purchases</h2>
          <p>10</p>
          <span>+1 last day</span>
        </div>
      </div>
      
      <div className="profit">
        <h2>Total profit</h2>
        <div className="profit-chart">Chart here</div>
      </div>
      
      <div className="objects">
        <div className="object">
          <div className="image-placeholder windmills-loft"></div>
          <p>Windmills Loft</p>
          <span>25%</span>
        </div>
        
        <div className="object">
          <div className="image-placeholder seaview-villa"></div>
          <p>Seaview Villa</p>
          <span>18%</span>
        </div>
        
        <div className="object">
          <div className="image-placeholder family-villa"></div>
          <p>Family Villa</p>
          <span>12%</span>
        </div>
      </div>
    </div>
  );
}

export default AIDashboard;
