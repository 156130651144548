import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import LoadingComp from './loading';
import { Box, Paper, Typography } from "@mui/material";
import SmallLoadingComp from './SmallLoading';
import { useMediaQuery } from 'react-responsive'
import Skeleton from '@mui/material/Skeleton';



const VulnerabilitiesOverTime = ({ selectedCompanyName, border, availableDates, setAvailableDates, setSelectedDate, godLoading, forReport }) => {
    const { user } = useAuth0();
    const [reportData, setReportData] = useState([]);
    const [paperElevation, setPaperElevation] = useState(3);
    const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

    useEffect(() => {
        const fetchData = async () => {
          try {
            const dateResponse = await axios.get(`${process.env.REACT_APP_APIURL}/api/available-dates/${selectedCompanyName}`
            );
            if(dateResponse.status === 404){ setAvailableDates([]);
                setSelectedDate([]);}else{
            // console.log(response.data)
            setAvailableDates(dateResponse.data);
            setSelectedDate(dateResponse.data[0]);
            console.log(dateResponse.data);}
            // const response = await axios.get(process.env.REACT_APP_APIURL + '/api/' + user["permissions/companies"] + '/data');
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }, [selectedCompanyName, user]);


      const formatTickItem = (tickItem) => {
        if(isMobile){
            if (tickItem.length > 10 ) {
                return `${tickItem.substring(0, 10)}...`;
            } else return tickItem
        }else {
        if (tickItem.length > 25 ) {
                return `${tickItem.substring(0, 24)}...`;
            } else return tickItem
        }
    };



    useEffect(() => {
        const fetchAllReports = async () => {
                try {
                    // http://localhost:3001/api/123abc/vulns-over-time?dates=[%272042-09-06.csv%27,%20%272031-09-14.csv%27,%20%272030-09-12.csv%27,%20%272023-09-01.csv%27,%20%272023-08-01.csv%27,%20%272023-07-01.csv%27]
                   console.log(availableDates)
                    const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/vulns-over-time?dates=${JSON.stringify(availableDates.slice(0, 6))}`);
                    if(response.status === 404){ setAvailableDates([]);
                        setSelectedDate([]);}else{
                            setReportData(response.data);

                }
                  
                } catch (error) {
                    console.error(`Error fetching data for date`, error);
                }
            }
        
        fetchAllReports();
    }, [availableDates]);

    if(!forReport) return (
        <div>
            {isMobile ? (
                (reportData.length > 1 && 
            <Box sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
            {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities Over Time</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :
         
            <LineChart width={350} height={150} data={reportData ? reportData : null} margin={{ top: 5, right: 50, left: -20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={(value) => value.date.replace('.csv', '')} />
                <YAxis />
                <Tooltip content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return (
                                <div className="custom-tooltip">
                                    <h4 className="tooltip-title">{label.replace('.csv', '')}</h4>
                                    <ul className="tooltip-values">
                                        {payload.map((entry, index) => (
                                            <div>
                                                <h4 className="tooltip-label">Vulnerabilities: <span className="tooltip-value">{entry.value}</span></h4>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            );
                        }
                        return null;
                    }} />                <Legend />
                <Line type="monotone" dataKey="vulnerabilitiesCount" stroke="#8884d8" />
            </LineChart>}
</div>
            </Box> 
        )


            ) : (

                (reportData.length > 1 && 
                <Box sx={border ? { width: 1220, height: 300 } : { width: 1760, height: 300 } } onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
                <div style={{ textAlign: 'center', padding: '20px' }}>

                {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities Over Time</Typography>}
                {godLoading ? <Skeleton variant="rounded" height={250} /> :

                <LineChart width={border ? 1190 : 1760} height={300} data={reportData ? reportData : null}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={(value) => value.date.replace('.csv', '')} />
                    <YAxis />
                    <Tooltip content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return (
                                <div className="custom-tooltip">
                                    <h4 className="tooltip-title">{label.replace('.csv', '')}</h4>
                                    <ul className="tooltip-values">
                                        {payload.map((entry, index) => (
                                            <div>
                                                <h4 className="tooltip-label">Vulnerabilities: <span className="tooltip-value">{entry.value}</span></h4>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            );
                        }
                        return null;
                    }} />
                    {/* <Legend />  */}
                     <Line type="monotone" dataKey="vulnerabilitiesCount" stroke="#1491CB" strokeWidth={3} />
                </LineChart>}
                </div>
                </Box> 
            
            

        )
        


            )}







        </div>
       
    )
    if(forReport) return(
        <div>
        {isMobile ? (
            (reportData.length > 1 && 
        <Box sx={{ width: 350, height: 150 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
        <div style={{ textAlign: 'center', padding: '20px' }}>
        {godLoading ? <Skeleton variant="text" sx={{ fontSize: '2rem', width: 425, marginLeft: 45 }} /> :  <Typography variant='h3' fontWeight={"bold"} >Vulnerabilities Over Time</Typography>}
            {godLoading ? <Skeleton variant="rounded" height={250} /> :
     
        <LineChart width={350} height={150} data={reportData ? reportData : null} margin={{ top: 5, right: 50, left: -20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={(value) => value.date.replace('.csv', '')} />
            <YAxis />
            <Tooltip content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                        return (
                            <div className="custom-tooltip">
                                <h4 className="tooltip-title">{label.replace('.csv', '')}</h4>
                                <ul className="tooltip-values">
                                    {payload.map((entry, index) => (
                                        <div>
                                            <h4 className="tooltip-label">Vulnerabilities: <span className="tooltip-value">{entry.value}</span></h4>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        );
                    }
                    return null;
                }} />                <Legend />
            <Line type="monotone" dataKey="vulnerabilitiesCount" stroke="#8884d8" />
        </LineChart>}
</div>
        </Box> 
    )


        ) : (

            (reportData.length > 1 && 
            <Box sx={border ? { width: 580, height: 300 } : { width: 880, height: 300 }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(3)}>
            <div style={{ textAlign: 'center', padding: '20px' }}>

            {godLoading ? <Skeleton variant="rounded" height={250} /> :

            <LineChart width={border ? 880 : 880} height={300} data={reportData ? reportData : null}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={(value) => value.date.replace('.csv', '')} style={{fontWeight: 'bold', fontSize: 12}} angle={-17} textAnchor="end" />
                <YAxis style={{fontWeight: 'bold'}}/>
                <Tooltip content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                        return (
                            <div className="custom-tooltip">
                                <h4 className="tooltip-title">{label.replace('.csv', '')}</h4>
                                <ul className="tooltip-values">
                                    {payload.map((entry, index) => (
                                        <div>
                                            <h4 className="tooltip-label">Vulnerabilities: <span className="tooltip-value">{entry.value}</span></h4>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        );
                    }
                    return null;
                }} />
                {/* <Legend/> */}
                <Line type="monotone" dataKey="vulnerabilitiesCount" stroke="#1491CB" name="Total Vulnerabilities" strokeWidth={3}/>
            </LineChart>}
            </div>
            </Box> 
        
        

    )
    


        )}

    </div>
    )
};

export default VulnerabilitiesOverTime;
