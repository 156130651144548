import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { mockPieData as data } from "../data/mockData";

const SmallLoadingComp = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
        <div className="center">
            <img src="assets/arg-black.png" alt="Argus Logo" className="logoLoadSmall">
            </img>

        </div>


  );
};

export default SmallLoadingComp;