import {React, useEffect, useState} from 'react';
import { Chart, Lines, Layer, Ticks, Tooltip } from 'rumble-charts';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper } from '@mui/material';




// Filter records with missing 'Plugin Publication Date' or 'CVSS v2.0 Base Score'


const LineChart = (props) => {
  const { jsonData } = props
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [avgData, setAvgData] = useState([]);
  const [paperElevation, setPaperElevation] = useState(3)

  useEffect(() => {
    // axios.get(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/data')
    // .then(jsonData => {
    
      const filteredData = jsonData.filter(record => record['Plugin Publication Date'] && record['CVSS v2.0 Base Score']);
  
      // Group by 'Plugin Publication Date' and compute the average 'CVSS v2.0 Base Score'
      let aggregatedData = {};
      filteredData.forEach(record => {
          const date = record['Plugin Publication Date'];
          if (!aggregatedData[date]) {
              aggregatedData[date] = { sum: 0, count: 0 };
          }
          aggregatedData[date].sum += parseFloat(record['CVSS v2.0 Base Score']);
          aggregatedData[date].count += 1;
      });
      setAvgData(Object.keys(aggregatedData).map(date => (
          aggregatedData[date].sum / aggregatedData[date].count
      )))
    // }) 
  
  }, [jsonData]);
  
  const series = [{
    name: 'Average Severity',
    data: avgData
}];

return (
            <Box sx={{width: 1220, height: 300}} onMouseEnter={()=> setPaperElevation(5)} onMouseLeave={()=> setPaperElevation(3)}>
      
      <Paper elevation={paperElevation} >
  <p>Average Severity Over Time</p>
  <Chart width={1200} height={300} series={series} minY={0}>
      <Layer width='90%' height='80%' position='middle center'>
          <Ticks
              axis='y'
              lineLength='100%'
              lineVisible={true}
              lineStyle={{ stroke: '#e5e5e5' }}
              labelStyle={{ textAnchor: 'end', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
              labelAttributes={{ x: -5 }}
          />
          <Ticks
              axis='x'
              labelFormat={index => new Date(index).toLocaleDateString()}
              labelStyle={{ textAnchor: 'middle', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
          />
          <Lines />
          {/* <Tooltip
              mode='hover'
              template={`
                  <div style="font-family: Arial; background-color: #fff; border: 1px solid #ccc; padding: 5px; border-radius: 3px;">
                      <strong>Date:</strong> {x}<br/>
                      <strong>Average Severity:</strong> {y}
                  </div>
              `}
          /> */}
      </Layer>
  </Chart>
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <span style={{ background: '#64B5F6', padding: '5px 10px', borderRadius: '2px', color: '#FFF' }}>Average Severity</span>
  </div>
  </Paper>
</Box>
)
};
export default LineChart;
