import { Box, useTheme, Popover, Button, IconButton  } from "@mui/material";
import {React, useState} from "react"
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataDomains } from "../../data/mockData";
import Header from "../../components/Header";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import DomainEntry from "../../components/domainEntry";
import PopOutDetails from "../../components/PopOutDetails";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/LinearProgress';
import axios from "axios";


const Invoices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoadingRefresh, setLoadingRefresh] = useState(false)
  const [progress, setProgress] = useState(0);

  const userCompany = user['permissions/companies']

  function dataTableLoading(){
    if(data.length === null){
      return false
    }else if (!data){
      return true
    }else{
      return false
    }
  }

  useEffect(()=>{
    fetch(process.env.REACT_APP_APIURL+'/api/'+userCompany+'/domains')
    .then((response)=> response.json())
    .then((json) => {setData(json)})
}, []);


const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const handleRefresh = () => {
  setLoadingRefresh(true)
  axios.get(process.env.REACT_APP_APIURL+'/api/'+userCompany+'/whois',{
    onDownloadProgress: progressEvent => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      
      setProgress(percentCompleted);
    }
  }).then(res => {
    if(res.data.msg === "no Changes"){
      setLoadingRefresh(false)
    }else{
    setData(res.data)
    setLoadingRefresh(false)
    }
  });

  // setLoadingRefresh(false)
};

const open = Boolean(anchorEl);
const id = open ? 'New Wallet Entry' : undefined;

  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Domain Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "blacklists",
      headerName: "Black Lists",
      flex: 1,
    },
    {
      field: "whois",
      headerName: "Who Is Info",
      flex: 1,
      renderCell: (params) =>[
        <div>
        <PopOutDetails data={params.value}/>
        </div>
      ]
    },
    {
      field: "added",
      headerName: "Date Added",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title="DOMAINS" subtitle="Argus Domain Watcher" />
      <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{backgroundColor: colors.blueAccent[400]}} >
        Add New Entry
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
<DomainEntry data={setData} close={setAnchorEl}/>
      </Popover>

      </div>
      
      <Box
        m="3px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[200],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[400],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

      <IconButton onClick={handleRefresh}>
      <RefreshIcon />
      </IconButton>
      {isLoadingRefresh ? <CircularProgress /> : null}

<DataGrid
          rows={data}
          columns={columns}
          loading={dataTableLoading()}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
          getRowId={(row) => (row._id)}
        />      </Box>
    </Box>
  );
};

export default Invoices;
