

// // export default ReportBuilder;
// import React, { useState, useEffect, useRef } from "react";
// import Button from "@mui/material/Button";
// import {
//   Box,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Snackbar,
//   TextField,
//   FormControlLabel,
//   Checkbox,
//   CircularProgress,
// } from "@mui/material";
// import axios from "axios";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import { useAuth0 } from "@auth0/auth0-react";
// import TopHostsBarChart from "../../components/TopHostsBarChart";
// import html2canvas from 'html2canvas';
// import CVSSDistributionChart from "../../components/CVSSDistributionChart";
// import UniqueHostCount from "../../components/UniqueHostCount";
// import VulnerabilityCount from "../../components/VulnerabilityCount";
// import CyberSecurityScore from "../../components/CyberSecurityScore";
// import VulnerabilitiesOverTime from "../../components/VulnerabilitiesOverTime";
// import OperatingSystemsBarChart from "../../components/OperatingSystemsBarChart";
// import BarChart from "../../components/BarChart";

// const ReportBuilder = () => {
//     const [file, setFile] = useState(null);
//     const [companies, setCompanies] = useState([]);
//     const [selectedCompany, setSelectedCompany] = useState("");
//     const [uploadSuccess, setUploadSuccess] = useState(false);
//     const [selectedCompanyName, setSelectedCompanyName] = useState("");
//     const [selectedDate, setSelectedDate] = useState();
//     const [sendEmailNotification, setSendEmailNotification] = useState(false);
//     const [dates, setDates] = useState([]);
//     const [detailedReport, setDetailedReport] = useState(false);
//     const [notes, setNotes] = useState("");
//     const [showNotes, setShowNotes] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [aiLoading, setAiLoading] = useState(false);

//     const { user, isAuthenticated, isLoading } = useAuth0();

//     const [godLoading, setGodLoading] = useState(false);
//     const [jsonData, setJsonData] = useState({});
//     const chartRef1 = useRef();
//     const chartRef2 = useRef();
//     const chartRef3 = useRef();
//     const chartRef4 = useRef();
//     const chartRef5 = useRef();
//     const chartRef6 = useRef();
//     const chartRef7 = useRef();
//     const chartRef8 = useRef();

//     useEffect(() => {
//       axios.get(`${process.env.REACT_APP_APIURL}/api/all/companies`).then((response) => {
//           setCompanies(response.data);
//       });
//     }, []);

//     const captureChart = async (chartRef) => {
//       const chartElement = chartRef.current;
//       const canvas = await html2canvas(chartElement, {
//         width: chartElement.scrollWidth,
//         height: chartElement.scrollHeight,
//         windowWidth: chartElement.scrollWidth,
//         windowHeight: chartElement.scrollHeight,
//         scale: 2 // Increase scale for better quality
//       });
//       return canvas.toDataURL('image/png');
//     };

//     const handleDownload = async () => {
//     setLoading(true); // Start loading
//     if (!showNotes) { setNotes(''); }
//       if (selectedDate && selectedCompanyName) {
        
//         try {
//           const response = await axios.get(`${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}`);
//           setJsonData(response.data);

//           // Wait for the charts to render
//           setTimeout(async () => {
//             const image1 = await captureChart(chartRef1);
//             const image2 = await captureChart(chartRef2);
//             const image3 = await captureChart(chartRef3);
//             const image4 = await captureChart(chartRef4);
//             const image5 = await captureChart(chartRef5);
//             const image6 = await captureChart(chartRef6);
//             const image7 = await captureChart(chartRef7);
//             const image8 = await captureChart(chartRef8);
            
//             const images = {
//               chart1: image1,
//               chart2: image2,
//               chart3: image3,
//               chart4: image4,
//               chart5: image5,
//               chart6: image6,
//               chart7: image7,
//               chart8: image8,
//             };

//             // Send data and images to backend
//             const pdfResponse = await axios.post(
//               `${process.env.REACT_APP_APIURL}/api/pdf/${selectedCompanyName}/${selectedDate}`,
//               { images, data: response.data, notes: notes },
//               { responseType: 'blob' }
//             );

//             // Download the generated PDF
//             const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', `argus100_${selectedCompanyName}_${selectedDate}.pdf`);
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             setLoading(false)
//             setUploadSuccess(true);
//           }, 1500); // Wait for 1500ms to ensure charts are rendered
//           ; // Stop loading
//         } catch (error) {
//           console.error("Error capturing chart:", error);
//         }
//       } else {
//         setLoading(false); // Stop loading if no company or date selected
//       }
//     };

//     const handleGenerateNotes = async () => {
//       setNotes('');
//       setAiLoading(true);
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_APIURL}/api/ai/completions/argus100`,
//           { company: selectedCompanyName, csv: selectedDate }
//         );
//         setNotes(response.data.notes);
//       } catch (error) {
//         console.error("Error generating notes:", error);
//       } finally {
//         setAiLoading(false);
//       }
//     };

//     useEffect(() => {
//         if (selectedCompanyName) {
//           axios.get(`${process.env.REACT_APP_APIURL}/api/available-dates/${selectedCompanyName}`).then((response) => {
//             setDates(response.data);
//             if (response.data.length > 0) {
//               setSelectedDate();
//               setShowNotes(false);
//               setNotes('');
//             }
//           });
//         }
//     }, [selectedCompanyName]);

//     if (isLoading) {
//         return <div>Loading ...</div>;
//     }
//     if (!isAuthenticated) {
//         return <div>Not Authenticated</div>;
//     }
//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <Box sx={{ ml: 10, mt: 2, width: "500px" }}>
//                 <h2>Download Report</h2>
//                 <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
//                     <InputLabel id="company-select-label">Company</InputLabel>
//                     <Select
//                         labelId="company-select-label"
//                         value={selectedCompany}
//                         onChange={(e) => {
//                             setSelectedCompany(e.target.value);
//                             setSelectedCompanyName(companies.find(company => company._id === e.target.value).company);
//                         }}
//                         label="Company"
//                     >
//                         {companies.map((company) => (
//                             <MenuItem key={company._id} value={company._id}>
//                                 {company.company}
//                             </MenuItem>
//                         ))}
//                     </Select>
//                 </FormControl>
//                 {dates.length === 0 ? null : (
//                   <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//                       <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
//                           <InputLabel id="date-select">Report</InputLabel>
//                           <Select
//                               labelId="date-select"
//                               value={selectedDate}
//                               onChange={(e) => {
//                                   setSelectedDate(e.target.value);
//                               }}
//                               label="Report"
//                           >
//                               {dates.map((date) => (
//                                   <MenuItem key={date} value={date}>
//                                       {date}
//                                   </MenuItem>
//                               ))}
//                           </Select>
//                       </FormControl>
//                   </Box>
//                 )}
//                 {/* <Box sx={{ display: "flex", alignItems: "center", mb: 5 }}>
//                     <FormControlLabel
//                         control={
//                             <Checkbox
//                                 checked={detailedReport}
//                                 onChange={() => setDetailedReport(!detailedReport)}
//                                 name="detailedReport"
//                             />
//                         }
//                         label="Detailed Report"
//                     />
//                 </Box> */}
//                 { selectedDate && (

//                     <Box sx={{ display: "flex", alignItems: "center", mb: 5 }}>
//                     <FormControlLabel
//                         control={
//                             <Checkbox
//                                 checked={showNotes}
//                                 onChange={() => setShowNotes(!showNotes)}
//                                 name="showNotes"
//                             />
//                         }
//                         label="Add Notes"
//                     />
//                 </Box>
//                 )}
              
//                 {showNotes && (
//                     <Box sx={{ mb: 5 }}>
//                         <TextField
//                             label="Notes"
//                             multiline
//                             rows={4}
//                             variant="outlined"
//                             fullWidth
//                             value={notes}
//                             onChange={(e) => setNotes(e.target.value)}
//                         />
//                         <Button
//                             variant="contained"
//                             color="secondary"
//                             onClick={handleGenerateNotes}
//                             disabled={aiLoading}
//                             sx={{ mt: 2 }}
//                         >
//                             {aiLoading ? <CircularProgress size={24} /> : 'Generate ArgusAI Notes'}
//                         </Button>
//                     </Box>
//                 )}
//                 <Button variant="contained" color="primary" onClick={handleDownload} disabled={loading || !selectedDate}>
//                     {loading ? <CircularProgress size={24} /> : 'Download Report'}
//                 </Button> 
//                 <div ref={chartRef1} style={{ width: '950px', height: '325px', position: 'absolute', top: '-9999px', left: '-9999px' }}>
//                     <TopHostsBarChart
//                       forReport={true}
//                       selectedDate={selectedDate}
//                       border={false}
//                       data={jsonData.topHostBarChart}
//                       company={selectedCompanyName}
//                       godLoading={godLoading}
//                     />
//                 </div>
//                 <div ref={chartRef2} style={{ width: '950px', height: '325px', position: 'absolute', top: '-9999px', left: '-9999px' }}>
//                     <CVSSDistributionChart
//                       selectedDate={selectedDate}
//                       forReport={true}
//                       border={false}
//                       data={jsonData.cvssDistribution}
//                       company={selectedCompanyName}
//                       godLoading={godLoading}
//                     />
//                 </div>
//                 <div ref={chartRef3} style={{ width: '150px', height: '150px', position: 'absolute', top: '-9999px', left: '-9999px' }}>
//                 <UniqueHostCount
//                               data={jsonData.uniqueHostCount}
//                               forReport={true}
//                               border={false}
//                               date={selectedDate}
//                       company={selectedCompanyName}
//                       godLoading={godLoading}
//                             />
//                 </div>
//                 <div ref={chartRef4} style={{ width: '150px', height: '150px', position: 'absolute', top: '-9999px', left: '-9999px' }}>
//                 <VulnerabilityCount
//                               border={false}
//                               forReport={true}
//                               data={jsonData.vulnerabilityCount}
//                               godLoading={godLoading}
//                             />
//                 </div>
//                 <div ref={chartRef5} style={{ width: '150px', height: '150px', position: 'absolute', top: '-9999px', left: '-9999px' }}>
//                 <CyberSecurityScore
//                               forReport={true}
//                               jsonData={jsonData}
//                               border={false}
//                               data={jsonData.cybersecurityScore}
//                               godLoading={godLoading}
//                             />
//                 </div>
//                 {dates.length > 0 ? <div ref={chartRef6} style={{ width: '950px', height: '325px', position: 'absolute', top: '-9999px', left: '-9999px'  }}>

//                 <VulnerabilitiesOverTime
//                                 selectedCompanyName={selectedCompanyName}
//                                 border={false}
//                                 forReport={true}
//                             //   setAvailableDates={setDates}
//                             //   setSelectedDate={setSelectedDate}
//                                 availableDates={dates}
//                                 godLoading={godLoading}
//                              />
//                  </div>: null}
//                  <div ref={chartRef7} style={{ width: '950px', height: '325px', position: 'absolute', top: '-9999px', left: '-9999px'  }}>
//                  <OperatingSystemsBarChart
//                                                   border={false}
//                                                   selectedDate={selectedDate}
//                                                   company={selectedCompanyName}
//                                                   data={jsonData.operatingSystems}
//                                                   forReport={true}
//                                                   godLoading={godLoading}
//                                                 />
//                 </div>
//                 <div ref={chartRef8} style={{ width: '950px', height: '325px', position: 'absolute', top: '-9999px', left: '-9999px'  }}>
//                 <BarChart  
//                                                   selectedDate={selectedDate}
//                                                   jsonData={jsonData}
//                                                   border={false}
//                                                   data={jsonData.barChart}
//                                                   company={selectedCompanyName}
//                                                   godLoading={godLoading}
//                                                   forReport={true}
//                                                 />
//                 </div>
//                 <Snackbar
//                     open={uploadSuccess}
//                     autoHideDuration={4000}
//                     onClose={() => setUploadSuccess(false)}
//                     message="File Generated successfully!"
//                 />
//             </Box>
//         </LocalizationProvider>
//     );
// };

// export default ReportBuilder;
// ReportBuilder.jsx

import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth0 } from "@auth0/auth0-react";
import TopHostsBarChart from "../../components/TopHostsBarChart";
import html2canvas from "html2canvas";
import CVSSDistributionChart from "../../components/CVSSDistributionChart";
import UniqueHostCount from "../../components/UniqueHostCount";
import VulnerabilityCount from "../../components/VulnerabilityCount";
import CyberSecurityScore from "../../components/CyberSecurityScore";
import VulnerabilitiesOverTime from "../../components/VulnerabilitiesOverTime";
import OperatingSystemsBarChart from "../../components/OperatingSystemsBarChart";
import BarChart from "../../components/BarChart";

const ReportBuilder = () => {
  const [reportType, setReportType] = useState("currentClient"); // 'domain' or 'currentClient'
  const [domainName, setDomainName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [dates, setDates] = useState([]);
  const [notes, setNotes] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [godLoading, setGodLoading] = useState(false);

  const chartRefs = useRef([]);

  const { isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/api/all/companies`)
      .then((response) => {
        setCompanies(response.data);
      });
  }, []);

  useEffect(() => {
    // Initialize refs only once
    if (chartRefs.current.length === 0) {
      chartRefs.current = Array.from({ length: 8 }, () => React.createRef());
    }
  }, []);

  useEffect(() => {
    if (reportType === "currentClient" && selectedCompanyName) {
      axios
        .get(
          `${process.env.REACT_APP_APIURL}/api/available-dates/${selectedCompanyName}`
        )
        .then((response) => {
          setDates(response.data);
          if (response.data.length > 0) {
            setSelectedDate();
            setShowNotes(false);
            setNotes("");
          }
        });
    }
  }, [reportType, selectedCompanyName]);

  const captureChart = async (chartRef) => {
    const chartElement = chartRef.current;
    if (!chartElement) {
      console.error("Chart element not found for ref:", chartRef);
      return null;
    }
    const canvas = await html2canvas(chartElement, {
      width: chartElement.scrollWidth,
      height: chartElement.scrollHeight,
      windowWidth: chartElement.scrollWidth,
      windowHeight: chartElement.scrollHeight,
      scale: 2, // Increase scale for better quality
    });
    return canvas.toDataURL("image/png");
  };

  const handleDownload = async () => {
    setLoading(true); // Start loading
    if (!showNotes) {
      setNotes("");
    }

    try {
      if (reportType === "currentClient") {
        if (selectedDate && selectedCompanyName) {
          // Existing logic for current client report
          const response = await axios.get(
            `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}`
          );
          setJsonData(response.data);

          // Wait for the charts to render
          setTimeout(async () => {
            const images = {};
            for (let i = 0; i < chartRefs.current.length; i++) {
              const image = await captureChart(chartRefs.current[i]);
              if (image) {
                images[`chart${i + 1}`] = image;
              }
            }

            // Send data and images to backend
            const pdfResponse = await axios.post(
              `${process.env.REACT_APP_APIURL}/api/pdf/${selectedCompanyName}/${selectedDate}`,
              { images, data: response.data, notes },
              { responseType: "blob" }
            );

            // Download the generated PDF
            const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `argus100_${selectedCompanyName}_${selectedDate}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setUploadSuccess(true);
            setLoading(false);
          }, 1500); // Wait for 1500ms to ensure charts are rendered
        } else {
          setLoading(false);
          return;
        }
      } else if (reportType === "domain") {
        if (domainName) {
          // Logic to generate domain report
          const response = await axios.post(
            `${process.env.REACT_APP_APIURL}/api/pdf/domain-report`,
            { domain: domainName, notes },
            { responseType: "blob" }
          );

          // Download the generated PDF
          // console.log(response.data.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `domain_report.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setUploadSuccess(true);
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error("Error generating report:", error);
      setLoading(false);
    }
  };

  const handleGenerateNotes = async () => {
    setNotes("");
    setAiLoading(true);
    try {
      let response;
      if (reportType === "currentClient") {
        response = await axios.post(
          `${process.env.REACT_APP_APIURL}/api/ai/completions/argus100`,
          { company: selectedCompanyName, csv: selectedDate }
        );
      } else if (reportType === "domain") {
        response = await axios.post(
          `${process.env.REACT_APP_APIURL}/api/ai/completions/domain`,
          { domain: domainName }
        );
      }
      setNotes(response.data.notes);
    } catch (error) {
      console.error("Error generating notes:", error);
    } finally {
      setAiLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (!isAuthenticated) {
    return <div>Not Authenticated</div>;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ ml: 10, mt: 2, width: "500px" }}>
        <h2>Download Report</h2>

        {/* Report Type Selection */}
        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <RadioGroup
            row
            aria-label="report-type"
            name="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <FormControlLabel
              value="currentClient"
              control={<Radio />}
              label="Current Client"
            />
            <FormControlLabel value="domain" control={<Radio />} label="Domain" />
          </RadioGroup>
        </FormControl>

        {reportType === "currentClient" && (
          <>
            {/* Company Selection */}
            <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="company-select-label">Company</InputLabel>
              <Select
                labelId="company-select-label"
                value={selectedCompany}
                onChange={(e) => {
                  setSelectedCompany(e.target.value);
                  const company = companies.find(
                    (company) => company._id === e.target.value
                  );
                  setSelectedCompanyName(company ? company.company : "");
                }}
                label="Company"
              >
                {companies.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Date Selection */}
            {dates.length > 0 && (
              <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                <InputLabel id="date-select-label">Report Date</InputLabel>
                <Select
                  labelId="date-select-label"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  label="Report Date"
                >
                  {dates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}

        {reportType === "domain" && (
          <>
            {/* Domain Name Input */}
            <TextField
              label="Domain Name"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
            />
          </>
        )}

        {/* Add Notes Option */}
        {(selectedDate || domainName) && (
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNotes}
                  onChange={() => setShowNotes(!showNotes)}
                  name="showNotes"
                />
              }
              label="Add Notes"
            />
          </Box>
        )}

        {/* Notes TextField */}
        {showNotes && (
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            {reportType != "domain" && 
            <Button
              variant="contained"
              color="secondary"
              onClick={handleGenerateNotes}
              disabled={aiLoading}
              sx={{ mt: 2 }}
            >
              {aiLoading ? <CircularProgress size={24} /> : "Generate AI Notes"}
            </Button>}
          </Box>
        )}

        {/* Download Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          disabled={
            loading ||
            (reportType === "currentClient" && !selectedDate) ||
            (reportType === "domain" && !domainName)
          }
          sx={{ mt: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : "Download Report"}
        </Button>

        {/* Hidden Charts for PDF Generation */}
{reportType === "currentClient" && (
  <React.Fragment>
    {/* Chart 1 */}
    <div
      ref={chartRefs.current[0]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "950px",
        height: "325px",
      }}
    >
      <TopHostsBarChart
        forReport={true}
        selectedDate={selectedDate}
        border={false}
        data={jsonData.topHostBarChart}
        company={selectedCompanyName}
        godLoading={godLoading}
      />
    </div>

    {/* Chart 2 */}
    <div
      ref={chartRefs.current[1]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "950px",
        height: "325px",
      }}
    >
      <CVSSDistributionChart
        selectedDate={selectedDate}
        forReport={true}
        border={false}
        data={jsonData.cvssDistribution}
        company={selectedCompanyName}
        godLoading={godLoading}
      />
    </div>

    {/* Chart 3 - Fixed Size */}
    <div
      ref={chartRefs.current[2]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "150px",
        height: "150px",
        overflow: "hidden", // Prevent overflow
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UniqueHostCount
        data={jsonData.uniqueHostCount}
        forReport={true}
        border={false}
        date={selectedDate}
        company={selectedCompanyName}
        godLoading={godLoading}
        sx={{ width: "100%", height: "100%" }} // Ensure it fills the parent
      />
    </div>

    {/* Chart 4 - Fixed Size */}
    <div
      ref={chartRefs.current[3]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-div9px",
        width: "150px",
        height: "150px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <VulnerabilityCount
        border={false}
        forReport={true}
        data={jsonData.vulnerabilityCount}
        godLoading={godLoading}
        sx={{ width: "100%", height: "100%" }}
      />
    </div>

    {/* Chart 5 - Fixed Size */}
    <div
      ref={chartRefs.current[4]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "150px",
        height: "150px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CyberSecurityScore
        forReport={true}
        jsonData={jsonData}
        border={false}
        data={jsonData.cybersecurityScore}
        godLoading={godLoading}
        sx={{ width: "100%", height: "100%" }}
      />
    </div>

    {/* Chart 6 */}
    {dates.length > 0 && (
      <div
        ref={chartRefs.current[5]}
        style={{ 
          position: "absolute", 
          top: "-9999px", 
          left: "-9999px",
          width: "950px",
          height: "325px",
        }}
      >
        <VulnerabilitiesOverTime
          selectedCompanyName={selectedCompanyName}
          border={false}
          forReport={true}
          availableDates={dates}
          godLoading={godLoading}
        />
      </div>
    )}

    {/* Chart 7 */}
    <div
      ref={chartRefs.current[6]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "950px",
        height: "325px",
      }}
    >
      <OperatingSystemsBarChart
        border={false}
        selectedDate={selectedDate}
        company={selectedCompanyName}
        data={jsonData.operatingSystems}
        forReport={true}
        godLoading={godLoading}
      />
    </div>

    {/* Chart 8 */}
    <div
      ref={chartRefs.current[7]}
      style={{ 
        position: "absolute", 
        top: "-9999px", 
        left: "-9999px",
        width: "950px",
        height: "325px",
      }}
    >
      <BarChart
        selectedDate={selectedDate}
        jsonData={jsonData}
        border={false}
        data={jsonData.barChart}
        company={selectedCompanyName}
        godLoading={godLoading}
        forReport={true}
      />
    </div>
  </React.Fragment>
)}


        {/* Snackbar Notification */}
        <Snackbar
          open={uploadSuccess}
          autoHideDuration={4000}
          onClose={() => setUploadSuccess(false)}
          message="Report generated successfully!"
        />
      </Box>
    </LocalizationProvider>
  );
};

export default ReportBuilder;
