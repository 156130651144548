import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const CommonCVE = ({ jsonData, border, data, godLoading }) => {
    const [PaperElevation, setPaperElevation] = useState(0);


  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
return (
    <Box  sx={{ maxWidth: 250, height:110, my: 2, mx: 'auto', cursor: 'pointer' }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)} >
        <Card sx={{ maxWidth: 250, height: 110, my: 0, mx: 'auto', backgroundColor: "#fcfcfc"}} elevation={!border ? -1 : PaperElevation} onClick={handleOpen}>
        <Tooltip title="Click to view description">

        <CardContent >
        {godLoading ? <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: 170, textAlign: "center", marginLeft: 3 }}/> : <Typography variant="h5" color="textSecondary" sx={{ textAlign: 'center' }} gutterBottom>
          Most Common Critical CVE
        </Typography>}
            {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 170, textAlign: "center", marginLeft: 3 }}/> : <Typography variant="h3" component="div" style={{ cursor: 'pointer' }} sx={{ textAlign: 'center', marginTop: 3 }}>
              {data ? data.mostCommonCVE : "No CVEs found"}
            </Typography>}
        </CardContent>
        </Tooltip>

      </Card>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>
        {data ? data.mostCommonCVE : "No CVEs found"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          {data ? data.cveDescription : "No description found"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>

  );
};

export default CommonCVE;