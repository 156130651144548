import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import axios from "axios";
import BarChart from "../../components/BarChart";
import PieChart from "../../components/PieChart";
import LineChart from "../../components/LineChart";
import LineChart2 from "../../components/LineChart2";
import AreaChart from "../../components/AreaChart";
import TopHostsBarChart from "../../components/TopHostsBarChart";
import { useAuth0 } from "@auth0/auth0-react";
import VulnerabilityCount from "../../components/VulnerabilityCount";
import OSBarChart from "../../components/OSBarChart";
import CVSSDistributionChart from "../../components/CVSSDistributionChart";
import VulnerabilitiesOverTime from "../../components/VulnerabilitiesOverTime";
import { useMediaQuery } from "react-responsive";
import CyberSecurityScore from "../../components/CyberSecurityScore";
import CriticalExploitable from "../../components/CriticalExploitable";
import CommonCVE from "../../components/CommonCVE";
import { Navigate, Link } from "react-router-dom";
import UniqueHostCount from "../../components/UniqueHostCount";
import OperatingSystemsBarChart from "../../components/OperatingSystemsBarChart";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import LanIcon from "@mui/icons-material/Lan";
import VtInfo from "../../components/VtInfo";
import DomainInfo from "../../components/DomainInfo";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Close } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash/debounce";

const Dashboard = ({setReportFunction, modalState, setModalState, setSelectedDate, selectedDate, availableDates, setAvailableDates, setSelectedCompanyName, selectedCompanyName }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [jsonData, setJsonData] = useState([]);
  const [data, setData] = useState([]);
  // const [availableDates, setAvailableDates] = useState([]);
  // const [selectedDate, setSelectedDate] = useState();
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
  const userCompany = user["permissions/companies"].toLowerCase();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [unfilteredJsonData, setUnfilteredJsonData] = useState([]);
  const [domainDashboard, setDomainDashboard] = useState(false);
  const [dashboardName, setDashboardName] = useState("Domain Info");
  const [godLoading, setGodLoading] = useState(true);

  // Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "92%",
    height: "95%",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
    outline: "none", // Removes the default focus outline
    overflowY: "auto", // Adds scroll to the modal if content is too large
  };
  // ----------

  const handleClick = (event) => {
    event.preventDefault();

    axios.get("http://localhost:3001/testAuth", {}).then((response) => {
      if (response.status === 200) {
        console.log(response);
      } else return console.log(JSON.stringify(response.data));
    });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/api/all/companies`)
      .then((response) => {
        console.log(response.data);
        setCompanies(response.data);
      });
  }, []);

// Fetch available dates when selectedCompanyName changes
useEffect(() => {

  const fetchAvailableDates = async () => {
    try {
      console.log("selectedCompanyName:", selectedCompanyName);
      const dateResponse = await axios.get(
        `${process.env.REACT_APP_APIURL}/api/available-dates/${selectedCompanyName}`
      );
      if (dateResponse.status === 404 || dateResponse.status === 500) {
        setAvailableDates([]);
        setSelectedDate([]);
      } else {
        
        console.log("use Effect from dashboard");
        console.log(dateResponse.data);
        setAvailableDates(dateResponse.data);
        setSelectedDate(dateResponse.data[0]);
        setGodLoading(false);
      }
    } catch (error) {
      console.error("Error fetching available dates:", error);
    }
  };
  setGodLoading(true);
  fetchAvailableDates();

}, [selectedCompanyName]);

// Fetch data when selectedDate changes
const debouncedFetchData = async () => {
    if (typeof selectedDate === "string" && selectedDate.length > 0) {
      try {
        console.log("selectedDate:", selectedDate);
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}`
        );
        if (response.status === 404 || response.status === 500) {
          setAvailableDates([]);
          setSelectedDate([]);
        } else {
          setData(response.data);
          console.log(response.data);
          setGodLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  } // Adjust the debounce delay as needed (e.g., 500 milliseconds)
 

useEffect(() => {
  setGodLoading(true);
  debouncedFetchData();
}, [selectedDate]);

  // pdf generation functions
  const [pdfDataUrl, setPdfDataUrl] = useState("");
  const [open, setOpen] = useState(modalState);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const handleClose = () => setModalState(false);

  
  const generatePdfDataUrl = async () => {
    setLoadingPDF(true); // Start loading indicator

    const input = document.getElementById("dashboard-content-2");
    const canvas = await html2canvas(input, {
      scale: 1,
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/png");

    // Create an 8.5 x 11 inch PDF (this is the standard size for PDFs)
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "in",
      format: "letter",
    });

    // Calculate the ratio to fit the canvas within the 8.5 x 11 inch page
    const pdfWidth = 8.5;
    const pdfHeight = 11;
    const canvasRatio = canvas.width / canvas.height;
    const pdfRatio = pdfWidth / pdfHeight;

    let imgWidth = pdfWidth;
    let imgHeight = pdfHeight;

    // Adjust width and height to maintain the aspect ratio of the canvas
    if (canvasRatio > pdfRatio) {
      // Canvas ratio is wider than PDF ratio
      imgHeight = imgWidth / canvasRatio;
    } else {
      // Canvas ratio is narrower than PDF ratio
      imgWidth = imgHeight * canvasRatio;
    }

    // Center the image on the PDF page
    const x = (pdfWidth - imgWidth) / 2;
    const y = (pdfHeight - imgHeight) / 2;

    pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight);

    setLoadingPDF(false); // End loading indicator

    return pdf.output("datauristring");
  };

  const handleOpenModal = async () => {
    setOpen(true); // Open the modal after setting the PDF data URL
  };

  useEffect(() => {
    setOpen(modalState);
  }, [modalState]);

  const handleGeneratePdf = async () => {
    setLoadingPDF(true); // Start loading
    const dataUrl = await generatePdfDataUrl();
    setPdfDataUrl(dataUrl);
    setPdfGenerated(true);
    setLoadingPDF(false); // End loading
  };

  const handleDownloadPdf = () => {
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = `${selectedCompanyName}-Report-${selectedDate}.pdf`;
    link.click();
    setPdfGenerated(false);
  };


  return (
    <Box m="20px" className="pageContainer">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="NETWORK DASHBOARD" subtitle="Look through reports and security information." />
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="pdf-preview-title"
            aria-describedby="pdf-preview-description"
          >
            <Box sx={style}>
              <Box display="flex" justifyContent="flex-end" mb={"5px"}>
                {loadingPDF ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={
                      !pdfGenerated ? handleGeneratePdf : handleDownloadPdf
                    }
                  >
                    {!pdfGenerated ? "Generate PDF" : "Download PDF"}
                  </Button>
                )}
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box mb={10} width={"1700px"} id={"dashboard-content-2"}>
                  <Grid Container paddingTop={5}>
                    <Grid item md={6} paddingTop={5}>
                      {" "}
                      <img
                        src="/assets/arg-color-white.png"
                        alt="Argus100 Logo"
                        width="200px"
                        height="auto"
                      />
                    </Grid>
                    <Grid item md={6} marginBottom={10} marginLeft={10}>
                      <Typography
                        variant="h1"
                        component="div"
                        sx={{ textAlign: "center" }}
                        textTransform={"capitalize"}
                      >
                        {" "}
                        {selectedCompanyName}{" "}
                      </Typography>
                      <Typography
                        variant="h1"
                        component="div"
                        sx={{ textAlign: "center" }}
                      >
                        Cybersecurity Report
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      my={isMobile ? 5 : -5}
                      mt={isMobile ? 7 : 1}
                      ml={isMobile ? 0 : null}
                      mb={isMobile ? -5 : null}
                    >
                      <Grid container spacing={isMobile ? 0 : 10}>
                        <Grid item xs={12} md={3}>
                          <Box my={0}>
                            <UniqueHostCount
                              jsonData={jsonData}
                              forReport={false}
                              data={data.uniqueHostCount}
                              border={false}
                              date={selectedDate}
                      company={selectedCompanyName}
                      godLoading={godLoading}

                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box my={0}>
                            <VulnerabilityCount
                            forReport={false}
                              jsonData={jsonData}
                              border={false}
                              data={data.vulnerabilityCount}
                              godLoading={godLoading}

                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Box my={0}>
                            <CyberSecurityScore
                              forReport={false}
                              jsonData={jsonData}
                              border={false}
                              data={data.cybersecurityScore}
                              godLoading={godLoading}

                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Box my={0}>
                            <CommonCVE
                              jsonData={jsonData}
                              border={false}
                              data={data.mostCommonCVE}
                              godLoading={godLoading}

                            />
                          </Box>
                        </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                              <Box my={2}>
                                                <TopHostsBarChart
                                                  godLoading={godLoading}
                                                  jsonData={jsonData}
                                                  border={false}
                                                  data={data.topHostBarChart}
                                                  date={selectedDate}
                                                  company={selectedCompanyName}
                                                />
                                              </Box>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                              <Box my={isMobile ? 8 : 3}>
                                                <PieChart
                                                  godLoading={godLoading}
                                                  jsonData={jsonData}
                                                  border={false}
                                                  data={data.pieChart}
                                                />
                                              </Box>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                              <Box my={isMobile ? 5 : 3}>
                                                <BarChart
                                                  forReport={false}
                                                  selectedDate={selectedDate}
                                                  jsonData={jsonData}
                                                  border={false}
                                                  data={data.barChart}
                                                  company={selectedCompanyName}
                                                  godLoading={godLoading}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                              <Box my={isMobile ? 5 : 6}>
                                                <OperatingSystemsBarChart
                                                  jsonData={unfilteredJsonData}
                                                  border={false}
                                                  selectedDate={selectedDate}
                                                  company={selectedCompanyName}
                                                  data={data.operatingSystems}
                                                  godLoading={godLoading}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                              <Box my={isMobile ? 5 : 4}>
                                                <CVSSDistributionChart
                                                  jsonData={jsonData}
                                                  border={false}
                                                  data={data.cvssDistribution}
                                                  godLoading={godLoading}
                                                />
                                              </Box>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                              <Box my={isMobile ? 5 : 6}>
                                                <VulnerabilitiesOverTime
                                                  selectedCompanyName={selectedCompanyName}
                                                  border={false}
                                                  setAvailableDates={setAvailableDates}
                                                  setSelectedDate={setSelectedDate}
                                                  availableDates={availableDates}
                                                  godLoading={godLoading}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                          <Grid Container paddingTop={13}>
                                            <Grid item lg={12} paddingBottom={5}>
                                              <Typography
                                                variant="h3"
                                                component="div"
                                                sx={{ textAlign: "center" }}
                                              >
                                                Cybersecurity services provided by Microset Systems Inc.
                                              </Typography>
                                              <Typography
                                                variant="p"
                                                component="div"
                                                sx={{ textAlign: "center" }}
                                              >
                                                700-2425 Matheson Blvd E, Mississauga, ON, L4W 5K4,
                                                Canada, +1.416.283.4949 cybersecurity@msi.net
                                                www.msi.net/argus100
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Box>

                                      {/* {pdfDataUrl && (
                                    <iframe
                                      src={pdfDataUrl && pdfDataUrl}
                                      title="PDF Preview"
                                      type="application/pdf"
                                      style={{ width: '100%', height: '100%' }}
                                    />
                                  )} */}
                                    </Box>
                                  </Modal>
                                </div>
                             {/* DASHBOARD BELOW -------------------------------- */}
                                <Box>
                                  {isMobile ? null : (
                                    <div>
                                      {user["permissions/roles"] === "admin" && (
                                        <FormControl fullWidth variant="outlined" sx={{ mb: 2, width: "200px" }}>
                                          <InputLabel id="company-select-label">{selectedCompanyName}</InputLabel>
                                          <Select
                                            labelId="company-select-label"
                                            value={selectedCompany}
                                            onChange={(e) => {
                                              setSelectedCompany(e.target.value);
                                              setSelectedCompanyName(
                                                companies[e.target.value - 1].company
                                              );
                                            }}
                                            label="Company"
                                          >
                                            {companies.map((company) => (
                                              <MenuItem key={company._id} value={company._id}>
                                                {company.company}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      )}

                                 
                                    </div>
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                {isMobile ? (
                                  <div>
                                    {user["permissions/roles"] === "admin" && (
                                      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                                        <InputLabel id="company-select-label">Company</InputLabel>
                                        <Select
                                          labelId="company-select-label"
                                          value={selectedCompany}
                                          onChange={(e) => {
                                            setSelectedCompany(e.target.value);
                                            setSelectedCompanyName(
                                              companies[e.target.value - 1].company
                                            );
                                          }}
                                          label="Company"
                                        >
                                          {companies.map((company) => (
                                            <MenuItem key={company._id} value={company._id}>
                                              {company.company}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )}

                               
                                  </div>
                                ) : null}
                              </Box>
                              <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Box mb={10} width={"1220px"} id={"dashboard-content"}>
                                  <Grid container spacing={5}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      my={isMobile ? 5 : -5}
                                      mt={isMobile ? 7 : 1}
                                      ml={isMobile ? 0 : null}
                                      mb={isMobile ? -5 : null}
                                    >
                                      <Grid container spacing={isMobile ? 0 : 10}>
                                        <Grid item xs={12} md={2}>
                                          <Box my={0}>
                                            <UniqueHostCount
                                              jsonData={jsonData}
                                              border={true}
                                              forReport={false}
                                              data={data.uniqueHostCount}
                                              date={selectedDate}
                                              company={selectedCompanyName}
                                              godLoading={godLoading}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <Box my={0}>
                                            <VulnerabilityCount
                                              forReport={false}
                                              jsonData={jsonData}
                                              border={true}
                                              data={data.vulnerabilityCount}
                                              godLoading={godLoading}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          <Box my={0}>
                                            <CyberSecurityScore
                                              forReport={false}
                                              jsonData={jsonData}
                                              border={true}
                                              data={data.cybersecurityScore}
                                              godLoading={godLoading}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <Box my={0}>
                                            <CriticalExploitable
                                              selectedDate={selectedDate}
                                              jsonData={jsonData}
                                              border={true}
                                              data={data.criticalExploitable}
                                              selectedCompanyName={selectedCompanyName}
                                              godLoading={godLoading}
                                            />
                                          </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                          <Box my={0}>
                                            <CommonCVE
                                              jsonData={jsonData}
                                              border={true}
                                              data={data.mostCommonCVE}
                                              godLoading={godLoading}
                                            />
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Box my={2}>
                                        <TopHostsBarChart
                                          godLoading={godLoading}
                                          jsonData={jsonData}
                                          border={true}
                                          data={data.topHostBarChart}
                                          date={selectedDate}
                                          company={selectedCompanyName}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Box my={isMobile ? 8 : 3}>
                                        <PieChart
                                          godLoading={godLoading}
                                          jsonData={jsonData}
                                          border={true}
                                          data={data.pieChart}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Box my={isMobile ? 5 : 3}>
                                        <BarChart
                                          forReport={false}
                                          jsonData={jsonData}
                                          border={true}
                                          data={data.barChart}
                                          selectedDate={selectedDate}
                                          company={selectedCompanyName}
                                          godLoading={godLoading}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Box my={isMobile ? 5 : 6}>
                                        <OperatingSystemsBarChart
                                          jsonData={unfilteredJsonData}
                                          border={true}
                                          selectedDate={selectedDate}
                                          company={selectedCompanyName}
                                          data={data.operatingSystems}
                                          godLoading={godLoading}
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Box my={isMobile ? 5 : 4}>
                                        <CVSSDistributionChart
                                          jsonData={jsonData}
                                          border={true}
                                          data={data.cvssDistribution}
                                          godLoading={godLoading}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <Box my={isMobile ? 5 : 6}>
                                        <VulnerabilitiesOverTime
                                          selectedCompanyName={selectedCompanyName}
                                          border={true}
                                          setAvailableDates={setAvailableDates}
                                          setSelectedDate={setSelectedDate}
                                          availableDates={availableDates}
                                          godLoading={godLoading}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Box>
                          );
                        };

                        export default Dashboard;
