import { Box, Button, useTheme, TextField, InputAdornment, IconButton } from "@mui/material";
import {React, useState, setState} from "react";
import { tokens } from "../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { preventDefault } from "@fullcalendar/core";
import axios from 'axios'
import { useAuth0 } from "@auth0/auth0-react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import DateSelector from "./DateSelector";



const WalletEntry = ({data, setData, close, keyInfo, setSave}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [title, setTitle] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [details, setDetails] = useState('');
  const [resource, setResource] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    const wallet = { _id: Math.floor(Math.random()* 100000000000), title: title, username: userName, password: password, details: details, resource: resource};
    // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(wallet), keyInfo).toString();
    // console.log(ciphertext)
    // axios.post(process.env.REACT_APP_APIURL+"/api/"+user["permissions/companies"]+'/wallet/create', wallet)
    //     .then(response => data(response.data))
    //     .then(close(null))
    console.log(data)
    setData([...data, wallet])
    setSave(true)
    close(null);
  };

  return (
    <Box width="100%" p="10px 10px 10px 10px">
     {console.log(keyInfo)}
          <form onSubmit={handleSubmit}>
            <Box
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                name="title"
                onChange={(event) => setTitle(event.target.value)}
              />
             
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="User Name"
                name="username"
                onChange={(event) => setUserName(event.target.value)}

                             />
              
              <TextField
                fullWidth
                variant="filled"
                type={showPassword ? "text" : "password" }
                label="Password"
                name="password"
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }} />


              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Details"
                name="details"
                onChange={(event) => setDetails(event.target.value)}

                
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Resource"
                name="resource"
                onChange={(event) => setResource(event.target.value)}

                
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Create Entry
              </Button>
            </Box>
          </form>
     
    </Box>
  );
};

export default WalletEntry;