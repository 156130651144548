import React, { useState, useEffect } from "react";
import {useAuth0} from "@auth0/auth0-react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import { height } from "@mui/system";

const ProductDisplay = ({user}) => (


    <Card className="productRoot" sx={{width: "100%"}}>
    <CardHeader title="Basic Plan" className="productHeader" />
    <Divider variant="middle" />
    <CardContent>
      <Typography variant="h4" align="center">
        $ 19.99
      </Typography>
      {/* <div className="productList">
        <Typography align="center">Manage tasks</Typography>
        <Typography align="center">Sync notes</Typography>
        <Typography align="center">Set deadline</Typography>
      </div> */}
    </CardContent>
    <Divider variant="middle" />
    <CardActions className="productAction">
 <form action={process.env.REACT_APP_APIURL+"/create-checkout-session/"+user['permissions/stripe_customer_id']} method="POST">
      <Button variant="contained" color="primary" className="productButton" type="submit">
        Buy
      </Button>
      </form>
    </CardActions>
  </Card>
    
//   <section>
//     <div className="product">
//       <img
//         src="https://i.imgur.com/EHyR2nP.png"
//         alt="The cover of Stubborn Attachments"
//       />
//       <div className="description">
//       <h3>Argus100 Basic</h3>
//       <h5>$29.99</h5>
//       </div>
//     </div>
//     <form action={process.env.REACT_APP_APIURL+"/create-checkout-session/"+user['permissions/stripe_customer_id']} method="POST">
//       <button type="submit">
//         Checkout
//       </button>
//     </form>
//   </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Product() {

const { user, isAuthenticated, isLoading } = useAuth0();
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay user={user}/>
  );
}