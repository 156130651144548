import { Box, IconButton, useTheme, Menu, MenuItem, Typography, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Logout from "../logout/logout";
import { useAuth0 } from "@auth0/auth0-react";
import DateSelector from "../../components/DateSelector";
import Billing from "../../components/Billing";
import { useLocation } from "react-router-dom";
import SearchBar from "../../components/SearchBar";


const Topbar = ({setSelectedDate, selectedDate, availableDates, setAvailableDates, selectedCompanyName}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { user } = useAuth0();

  const settings = ['Logout', 'Billing']; // Add 'Billing' to the settings array
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();
  const path = location.pathname;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

return (
  <Box
    position="fixed"
    top={0}
    zIndex={100}
    display="flex"
    justifyContent="space-between"
    p={2}
    sx={{ backgroundColor: colors.primary[500], width: "100%", height: "55px"}}
  >

    {/* DATE SELECTOR */}
    <Box display="flex" alignItems="center" position="fixed" marginTop="-7px" marginLeft={"-20px"}>
     {user["permissions/paid"] === "true" ? <DateSelector
        availableDates={availableDates}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        setAvailableDates={setAvailableDates}
      /> : null}
    </Box>

    {/* SEARCH BAR */}
    <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
{  user["permissions/paid"] === "true" ?    <SearchBar selectedCompanyName={selectedCompanyName} selectedDate={selectedDate}/>
: null }    </Box>

    {/* ICONS */}
    <Box display="flex" alignItems="center" marginRight={"70px"}>
    <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Typography textAlign="center">
            <Billing />
            <Logout />
          </Typography>
        </Menu>
        <IconButton onClick={handleOpenUserMenu}>
          <PersonOutlinedIcon alt="User Account" sx={{ color: colors.primary[1000] }} />
        </IconButton>
    </Box>
  </Box>
);
};

export default Topbar;
