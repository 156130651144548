import { Box } from "@mui/material";
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComp from "../../components/loading";
import { Navigate } from "react-router-dom";

const Bar = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingComp></LoadingComp>;
  } if (!isAuthenticated)return (<Navigate to="/login"/>); if(user["permissions/paid"] === "false") return(<Navigate to="/payment" />); else return (
<Box height={"100%"}>
<h1> Hello world</h1>
     

     <Header title="Bar Chart" subtitle="Simple Bar Chart" />
     <BarChart />

</Box>  

     
    
  );
};

export default Bar;
