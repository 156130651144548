// import React from 'react';
// import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList } from 'recharts';
// import { Card, Box } from '@mui/material';

// const OSBarChart = ({ jsonData }) => {
//   const osData = [];

//   // Aggregate data by OS
//   jsonData.forEach(record => {
//     const os = record['Operating System'];
//     const index = osData.findIndex(item => item.name === os);
//     if (index === -1) {
//       osData.push({ name: os, count: 1 });
//     } else {
//       osData[index].count += 1;
//     }
//   });

//   return (
//     <Box width={1200} height={300}>
//     <Card>

//     <BarChart
//       width={1200}
//       height={400}
//       data={osData}
//       margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
//     >
//       <CartesianGrid strokeDasharray="3 3" />
//       <XAxis dataKey="name" />
//       <YAxis />
//       <Tooltip />
//       <Bar dataKey="count" fill="#8884d8">
//         <LabelList dataKey="count" position="top" />
//       </Bar>
//     </BarChart>

//     </Card>


//     </Box>
   
//   );
// };

// export default OSBarChart;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList } from 'recharts';

const OSBarChart = ({ jsonData }) => {
  const osData = [];

  // Function to classify the OS
  const classifyOS = (osString) => {
    if (!osString) return 'Unknown';
  
    // Windows classifications
    if (osString.includes('Windows')) {
      if (osString.includes('XP')) return 'Windows XP';
      if (osString.includes('7')) return 'Windows 7';
      if (osString.includes('8')) return 'Windows 8';
      if (osString.includes('10')) return 'Windows 10';
      return 'Windows (Other)';
    }
  
    // macOS classifications
    if (osString.includes('Mac OS') || osString.includes('macOS')) {
      if (osString.includes('10.15')) return 'macOS Catalina';
      if (osString.includes('10.14')) return 'macOS Mojave';
      // Add other macOS versions as necessary
      return 'macOS (Other)';
    }
  
    // Linux classifications
    if (osString.includes('Linux') || osString.includes('Ubuntu')) {
      return 'Linux';
    }
  
    // Mobile OS classifications
    if (osString.includes('iOS')) return 'iOS';
    if (osString.includes('Android')) return 'Android';
  
    // Network devices
    if (osString.includes('Router') || osString.includes('Switch')) {
      return 'Network Device';
    }
    console.log("Unclassified OS:", osString); // Log any OS strings that aren't classified

    // For other types or unidentified OS
    return 'Unknown';
  };

  // Aggregate data by OS
  jsonData.forEach(record => {
    const os = classifyOS(record['Operating System']);
    const index = osData.findIndex(item => item.name === os);
    if (index === -1) {
      osData.push({ name: os, count: 1 });
    } else {
      osData[index].count += 1;
    }
  });

  // Sort the data by count
  osData.sort((a, b) => b.count - a.count);

  return (
    <BarChart
      width={600}
      height={400}
      data={osData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="count" fill="#1491CB">
        <LabelList dataKey="count" position="top" />
      </Bar>
    </BarChart>
  );
};

export default OSBarChart;
