import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";

function VtInfo() {
  const [data, setData] = useState();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APIURL}/api/vtinfo/${user["permissions/domain"]}`
        );
        setData(response.data.attributes);
        console.log(response.data.data.attributes);
        console.log(data);
        setData(response.data.data.attributes);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return (
      null
    );
  } else
    return (
      <Box>
        <Grid container>
          {Object.keys(data.popularity_ranks).length === 0 ? null : (
            <Grid item xs={isMobile ? 12 : 6}>
              <Box width={isMobile ? 300 : 620}>
                <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                  Domain Popularity Rankings
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <TableContainer
                      sx={
                        isMobile
                          ? { maxHeight: 330, width: 300, padding: "5px" }
                          : {
                              maxHeight: 330,
                              width: 620,
                              paddingBottom: "5px",
                              padding: "5px",
                            }
                      }
                    >
                      <Table aria-label="domain information table">
                        <TableBody>
                        
                          <Grid container spacing={1}>
                            {Object.keys(data.popularity_ranks).map((key) => (
                              <Grid item xs={isMobile ? 12 : 4} key={key}>
                                <Paper elevation={3}>
                                  <Box paddingLeft={"5px"}>
                                    <Typography
                                      variant="h6"
                                      fontWeight={"bold"}
                                    >
                                      {key}
                                    </Typography>
                                    <Typography variant="body1">
                                      Timestamp:{" "}
                                      {new Date(
                                        data.popularity_ranks[key].timestamp *
                                          1000
                                      ).toLocaleString()}
                                    </Typography>
                                    <Typography variant="body1">
                                      Rank: {data.popularity_ranks[key].rank}
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Grid>
                            ))}
                          </Grid>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}

          {Object.keys(data.categories).length === 0 ? null : (
            <Grid item xs={isMobile ? 12 : 6}>
              <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                Domain Internet Identification Classification
              </Typography>
              <Box width={isMobile ? 300 : 620}>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <TableContainer
                      sx={
                        isMobile
                          ? { maxHeight: 330, width: 300, padding: "5px" }
                          : {
                              maxHeight: 330,
                              width: 620,
                              paddingBottom: "5px",
                              padding: "5px",
                            }
                      }
                    >
                      <Table aria-label="domain information table">
                        <TableBody>
                          <Grid container spacing={1}>
                            {Object.keys(data.categories).map((key) => (
                              <Grid item xs={isMobile ? 12 : 4} key={key}>
                                <Paper elevation={3}>
                                  <Box paddingLeft={"5px"}>
                                    <Typography
                                      variant="h6"
                                      fontWeight={"bold"}
                                    >
                                      {key}
                                    </Typography>
                                    <Typography variant="body1">
                                      {data.categories[key]}
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Grid>
                            ))}
                          </Grid>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      // null
    );
}

export default VtInfo;
