import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./scenes/login";
import Logout from "./scenes/logout/logout";
import{CookiesProvider} from 'react-cookie'
import { Auth0Provider } from "@auth0/auth0-react";
import Bar from "./scenes/bar";
// import Product from "./components/Product";
import Payment from "./scenes/payment";
import DocCenter from "./scenes/documentcenter";
import CustomSignup from "./scenes/signup";
import AIDashboard from "./scenes/AIDashboard";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain="argus100-en-can.us.auth0.com"
    clientId="ZkPpRTndn22h9MQatunknMigIXKF6Nk2"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
  <CookiesProvider>
  {/* <React.StrictMode> */}
    <BrowserRouter>
    <Routes>
    <Route path="/ai-dashboard" element={<AIDashboard/>} />
    <Route path="/signup" element={<CustomSignup/>}/>
    <Route path="/login" element={<Login/>} />
    <Route path="/logout" element={<Logout/>} />
    <Route path="/payment" element={<Payment/>} />
    <Route index path="/*" element={<App />} />    
    </Routes>
    </BrowserRouter>
  {/* </React.StrictMode> */}
  </CookiesProvider>
  </Auth0Provider>
);
