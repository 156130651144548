import React, { useState } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Slide from '@mui/material/Slide';

const SearchBar = ({ selectedCompanyName, selectedDate }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add isLoading state
    const [searchData, setSearchData] = useState([]);
    const [searchBarDisabled, setSearchBarDisabled] = useState(false); // Add searchBarDisabled state
    const [argusAIResponse, setArgusAIResponse] = useState([]); // Add argusAIResponse state


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    
    const handleSearch = async () => {
        if (isExpanded) {
            setIsLoading(true); // Set isLoading to true when search starts
            setSearchBarDisabled(true); // Disable the search bar while searching

            // Make API call here using searchTerm
            // Replace the following code with your actual API call logic
            const searchResponse = await axios.get(
                `${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/${selectedDate}/search?q=${searchTerm}`
            );
            console.log(searchResponse.data);
            setSearchData(searchResponse.data.searchResults);
            setArgusAIResponse(searchResponse.data.argusResponse);
            setDialogOpen(true);
            setIsLoading(false); // Set isLoading to false when search completes
            setSearchBarDisabled(false); // Enable the search bar after search completes
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogData(null);
        setSearchOpen(false);
        setIsExpanded(false); // Reset isExpanded state
        setIsLoading(false); // Reset isLoading state
        setSearchBarDisabled(false); // Reset searchBarDisabled state
    };

    const handleExpand = () => {
        setIsExpanded(true);
    };

    const handleCollapse = () => {
        setTimeout(() => {
            setIsExpanded(false);
        }, 9000);
    };

    const handleOpenSearch = () => {
        setSearchOpen(true);
        setIsLoaded(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default behavior of the event
            handleSearch();
        }
    };

   

    const columns = [
        { field: 'Host', headerName: 'Host', width: '100', editable: true },
        { field: 'Name', headerName: 'Name', width: '100', editable: true },
        { field: 'CVE', headerName: 'CVE', width: '150' },
        { field: 'Risk', headerName: 'Risk', width: '100' },
        { field: 'Protocol', headerName: 'Protocol', width: '50' },
        { field: 'Synopsis', headerName: 'Synopsis', width: '300' },
        { field: 'Description', headerName: 'Description', width: '300' },
        { field: 'Solution', headerName: 'Solution', width: '300' },
        { field: 'See Also', headerName: 'See Also', width: '150' },

        // ... add more columns as per your data structure
    ];

    return (
        <div>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '20px',
                    width: isExpanded ? 350 : 110,
                    transition: 'opacity 1.5s, width 1.5s',
                    height: '35px',
                }}
            >
                {isLoading && (
                    <Box sx={{ height: '5%', width: '99%', mb: '-29px', ml: '25px' }}>
                        <LinearProgress />
                    </Box>
                )}
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={isExpanded ? 'Search your company' : 'Search...'}
                    inputProps={{ 'aria-label': 'Search your company' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={handleExpand}
                    onBlur={handleCollapse}
                    onKeyDown={handleKeyDown} // Add event listener for 'keydown' event
                    disabled={searchBarDisabled} // Disable the search bar based on searchBarDisabled state
                />
                <IconButton
                    type="button"
                    sx={{
                        p: '10px',
                        color: 'black',
                        cursor: isExpanded ? 'pointer' : 'default',
                    }}
                    aria-label="search"
                    onClick={isExpanded ? handleSearch : undefined}
                >
                    <SearchIcon />
                </IconButton>
            </Paper>

            <Dialog fullScreen open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>{argusAIResponse.length === 0 ? "Search Results" : "Argus AI Search Results"}</DialogTitle>
              
                <DialogContent dividers>
          {argusAIResponse.length === 0 ? <div>
            <Typography gutterBottom>
           See below for results related to {searchTerm}
          </Typography>
          </div> :   <div>
            <Typography gutterBottom>
           {argusAIResponse}
          </Typography>
          <Typography gutterBottom></Typography>
          </div>}
          
                    <DataGrid
                        components={{
                            Toolbar: GridToolbarContainer,
                        }}
                        componentsProps={{
                            toolbar: {
                                children: [
                                    <GridToolbarQuickFilter />,
                                    <GridToolbarExport />,
                                ],
                            },
                        }}
                        rows={searchData.map((vuln, index) => ({ ...vuln, id: index }))}
                        columns={columns}
                        pageSize={50}
                        autoHeight={false} // Disable auto height for the grid
                        getDetailPanelContent={({ row }) => <div>Row ID: {row.id}</div>}
  getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SearchBar;
