import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import Header from "../../components/Header";
import DomainInfo from "../../components/DomainInfo";
import VtInfo from "../../components/VtInfo";
import MonitorAgents from "../../components/MonitorAgent";

const DomainDashboard = () => {
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DOMAIN DASHBOARD" subtitle="Look through insights into your domain." />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box mb={10} width={"1320px"}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
            <MonitorAgents />

            </Grid>
            <Grid item xs={12}>
              <Box mt={5}>
                <VtInfo />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={5}>
              <DomainInfo />

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DomainDashboard;
