import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuItem } from '@mui/material';

const Logout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: "" + process.env.REACT_APP_URL + "/login" } });
  };

  return (
      <MenuItem onClick={handleLogout}>Log Out</MenuItem>

    
  );
};

export default Logout;