import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import {useCookies} from 'react-cookie'
import { useAuth0 } from "@auth0/auth0-react";
import Header from '../../components/Header';
import Document from '../../components/Document';
import FileUploadComponent from '../../components/upload';

const UploadCenter = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
      }if(!isAuthenticated){
        return <div>Not Authenticated</div>;
      }else return (

   <FileUploadComponent/>


      );










}  
export default UploadCenter;