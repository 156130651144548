import {React, useEffect, useState, useCallback} from "react";
import { Box, TextField, IconButton, InputAdornment, Grid, Stack } from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem, NoResultsOverlay, NoRowsOverlay  } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataCredentials } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios"
import { ForkRight, Visibility, VisibilityOff, } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { color } from "@mui/system";
import WalletEntry from "../../components/WalletEntry";




const CriticalServices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const userCompany = user['permissions/companies']

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  const [data, setData] = useState([])
  const [rows, setRows] = useState(data);
  const [anchorEl, setAnchorEl] = useState(null);

  function dataTableLoading(){
    if(data.length === null){
      return false
    }else if (!data.length){
      return true
    }else{
      return false
    }
  }

  useEffect(()=>{
      fetch(process.env.REACT_APP_APIURL+'/api/'+userCompany+'/wallet')
      .then((response)=> response.json())
      // .then((json) => {setData(json)})

  }, []);


  const deleteRow = useCallback(
    (id) => () => {
      setTimeout(() => {
        axios.post(process.env.REACT_APP_APIURL+'/api/'+user['permissions/companies']+'/wallet/delete/'+id+"")
        .then(response => setData(response.data));
      });
    },
    [],
  );

function stoppedEditing(row){
          const wallet = { fieldInfo: row.value };
    axios.post(process.env.REACT_APP_APIURL+'/api/'+user['permissions/companies']+'/wallet/update/'+row.id+'/'+row.field+"", wallet)
        .then(response => setData(response.data));
}

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};

const open = Boolean(anchorEl);
const id = open ? 'New Wallet Entry' : undefined;

  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    {
      field: "title",
      headerName: "End Point",
      flex: 1,
      cellClassName: "name-column--cell",
      editable: true
    },
    {
      field: "username",
      headerName: "Type",
      flex: 1,
      editable: true

    },
    {
      field: "password",
      headerName: "Status",
      flex: 1,
      editable: true,
    },
    {
      field: "details",
      headerName: "Details",
      flex: 1,
      editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={deleteRow(params.id)}
        />
      ],
    },
  ];

  if (isLoading) {
    return <div>Loading ...</div>;
  }else return (
    
    
    <Box m="20px">
      <Header
        title="CRITICAL SERVICES"
        subtitle="Monitor what's important."
      />
      <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{backgroundColor: colors.blueAccent[400]}} >
        New HTTPS Monitor
      </Button>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{backgroundColor: colors.blueAccent[400], margin: 1}} >
        New Generic Monitor
      </Button>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{backgroundColor: colors.blueAccent[400]}} >
        New Port Monitor
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
<WalletEntry data={setData} close={setAnchorEl} />
      </Popover>

      </div>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[200],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[400],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[400],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          loading={dataTableLoading()}
          components={{ Toolbar: GridToolbar, NoRowsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No rows in DataGrid
      </Stack>
    ),
    NoResultsOverlay: () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        Local filter returns no result
      </Stack>
    ) }}
          componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
          getRowId={(row) => (row._id)}
          onCellEditCommit={(row) => stoppedEditing(row)}
        />
      </Box>
    </Box>
  );
};

export default CriticalServices;