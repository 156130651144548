import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Clients from "./scenes/clients";
import Invoices from "./scenes/domains";
import Wallet from "./scenes/wallet";
import Form from "./scenes/form";
import FAQ from "./scenes/faq";
import DocCenter from "./scenes/documentcenter";
import { useAuth0 } from "@auth0/auth0-react";
import CriticalServices from "./scenes/critical";
import LoadingComp from "./components/loading";
import TopBar2 from "./scenes/global/TopBar2";
import UploadCenter from "./scenes/uploadcenter";
import { useLocation } from "react-router-dom";
import DomainDashboard from "./scenes/domain";
import Monitor from "./scenes/monitor";
import Bottombar from "./scenes/global/Bottombar";
import ReportBuilder from "./scenes/reportbuilder";



// import Login from "./scenes/login";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";



function App() {
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const [modalState, setModalState] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedCompanyName, setSelectedCompanyName] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setSelectedCompanyName(user["permissions/companies"].toLowerCase());
    }
  }, [isAuthenticated, user]);
 
  
if (isLoading) {
  return <LoadingComp />;
}

if (!isAuthenticated) {
  return <Navigate to="/login" />;
}

if (user && user["permissions/paid"] === "false") {
  return <Navigate to="/payment" />;
} 

return (
      <div>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <Sidebar isSidebar={isSidebar} /> 
          <main className="content">            
            <Topbar setIsSidebar={setIsSidebar} setAvailableDates={setAvailableDates} selectedCompanyName={selectedCompanyName} setSelectedCompanyName={setSelectedCompanyName}
               setSelectedDate={setSelectedDate}  availableDates={availableDates} selectedDate={selectedDate}
                />
            <Bottombar modalState={modalState} setModalState={setModalState}/>
            {/* <TopBar2/> */}

            <Routes>
              <Route path="/" element={<Dashboard modalState={modalState}
               setModalState={setModalState} setAvailableDates={setAvailableDates} 
               setSelectedDate={setSelectedDate}  availableDates={availableDates} selectedDate={selectedDate}
               selectedCompanyName={selectedCompanyName} setSelectedCompanyName={setSelectedCompanyName} />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/domains" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/critical" element={<CriticalServices />} />
              <Route path="/documents" element={<DocCenter />} />
              <Route path="/upload" element={<UploadCenter/>}/>
              <Route path="/report-builder" element={<ReportBuilder/>}/>
              <Route path="/domain-dashboard" element={<DomainDashboard/>}/>
              <Route path="/monitor" element={<Monitor/>}/>
           </Routes>
          
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
    </div>

    )
}
// }

export default App;
// import { useState } from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
// import Dashboard from "./scenes/dashboard";
// import Clients from "./scenes/clients";
// import Invoices from "./scenes/domains";
// import Wallet from "./scenes/wallet";
// import Form from "./scenes/form";
// import FAQ from "./scenes/faq";
// import DocCenter from "./scenes/documentcenter";
// import { useAuth0 } from "@auth0/auth0-react";
// import CriticalServices from "./scenes/critical";
// import LoadingComp from "./components/loading";
// import TopBar2 from "./scenes/global/TopBar2";
// import UploadCenter from "./scenes/uploadcenter";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";
// import CustomSignup from "./scenes/signup";

// function ProtectedRoutes() {
//     const { user, isAuthenticated, isLoading } = useAuth0();

//     if (isLoading) {
//         return <LoadingComp />;
//     } else if (!isAuthenticated) {
//         return <Navigate to="/login" />;
//     } else if (user && user["permissions/paid"] === "false") {
//         return <Navigate to="/payment" />;
//     } else return (

//         <Routes>
//             <Route path="/" element={<Dashboard />} />
//             <Route path="/clients" element={<Clients />} />
//             <Route path="/wallet" element={<Wallet />} />
//             <Route path="/domains" element={<Invoices />} />
//             <Route path="/form" element={<Form />} />
//             <Route path="/faq" element={<FAQ />} />
//             <Route path="/critical" element={<CriticalServices />} />
//             <Route path="/documents" element={<DocCenter />} />
//             <Route path="/upload" element={<UploadCenter />} />
//         </Routes>
    
//     );
// }

// function App() {
//     const [theme, colorMode] = useMode();
//     const [isSidebar, setIsSidebar] = useState(true);
//     const { user, isAuthenticated, isLoading } = useAuth0();

//     return (
//         <div>
//             <ColorModeContext.Provider value={colorMode}>
//                 <ThemeProvider theme={theme}>
//                     <CssBaseline />
//                     <div className="app">
//                     {isAuthenticated ? <Sidebar isSidebar={isSidebar} /> : null } 

//                     <main className="content">
//                     {isAuthenticated ? <Topbar setIsSidebar={setIsSidebar} /> : null }

//                             {/* <TopBar2/> */}
//                             <Routes>
//                                 <Route path="/" element={<ProtectedRoutes />} />
//                                 {/* Add any other public routes here */}
//                                 <Route path="/signup" element={<CustomSignup />} /> 
//                             </Routes>
//                         </main>
//                     </div>
//                 </ThemeProvider>
//             </ColorModeContext.Provider>
//         </div>
//     );
// }

// export default App;
