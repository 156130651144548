import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";


const Billing = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    const handleBillingClick = async () => {
        console.log("HELLO")
        try {
            const response = await axios.post(`${process.env.REACT_APP_APIURL}/create-billing-portal-session`, {customerId: user["permissions/stripe_customer_id"]});
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
                <MenuItem onClick={handleBillingClick}>Billing</MenuItem>
      
    );
};

export default Billing;
