import * as React from 'react';
import { Box } from '@mui/material';
import MonitorAgents from '../../components/MonitorAgent';
import SmallLoadingComp from '../../components/SmallLoading';
import { useAuth0 } from "@auth0/auth0-react";

const Monitor = () => {
const { user, isAuthenticated, isLoading } = useAuth0();
const [firstLogin, setFirstLogin] = React.useState(true);


const handleFirstLoginChange = (value) => {
    setFirstLogin(value);
  };
    const mockAgentData = [
        { id: 1, name: "Agent A", status: "Online", cpuUsage: 40, ramUsage: 70 },
        { id: 2, name: "Agent B", status: "Offline", cpuUsage: 0, ramUsage: 0 },
        // ... more agents if needed
      ];
      


return (

isLoading ? <SmallLoadingComp/> :
<Box>
<MonitorAgents firstLogin={firstLogin} onFirstLoginChange={handleFirstLoginChange}/>
</Box>
)
}

export default Monitor