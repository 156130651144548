import {React, useEffect, useState} from 'react';
import { Chart, Lines, Layer, Ticks, Tooltip } from 'rumble-charts';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from '@mui/material';




// Filter records with missing 'Plugin Publication Date' or 'CVSS v2.0 Base Score'


const LineChart2 = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [seriesData, setSeries] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_APIURL+'/api/'+user["permissions/companies"]+'/data')
    .then(jsonData => {

// Assuming jsonData is your parsed CSV in JSON format

// 1. Filter out rows with missing 'Plugin Publication Date' or 'Risk'
const filteredData = jsonData.data.filter(record => record['Plugin Publication Date'] && record['Risk']);

// 2. Group by 'Plugin Publication Date' and 'Risk' to get the count of each risk level for each date
let aggregatedData = {};
filteredData.forEach(record => {
    const date = record['Plugin Publication Date'];
    const risk = record['Risk'];
    if (!aggregatedData[date]) {
        aggregatedData[date] = {};
    }
    if (!aggregatedData[date][risk]) {
        aggregatedData[date][risk] = 0;
    }
    aggregatedData[date][risk] += 1;
});

// 3. Convert the aggregated data to a format suitable for Rumble Charts
setSeries(Object.keys(aggregatedData).map(date => (
    Object.keys(aggregatedData[date]).map(risk => ({
        name: risk,
        data: aggregatedData[date][risk]
    }))
)));




    
    }) 
  
  }, []);
  
  

return (
  <Box>
      <div>
      {console.log(seriesData)}
        <h2>Risk Level Trends Over Time</h2>
        <Chart width={600} height={300} series={seriesData} minY={0}>
            <Layer width='80%' height='80%' position='middle center'>
                <Ticks
                    axis='y'
                    lineLength='100%'
                    lineVisible={true}
                    lineStyle={{ stroke: '#e5e5e5' }}
                    labelStyle={{ textAnchor: 'end', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
                    labelAttributes={{ x: -5 }}
                />
                <Ticks
                    axis='x'
                    labelFormat={index => new Date(index).toLocaleDateString()}
                    labelStyle={{ textAnchor: 'middle', fontSize: 12, fontFamily: 'Arial', fill: '#8b8b8b' }}
                />
                <Lines />
                {/* <Tooltip
                    mode='hover'
                    template={`
                        <div style="font-family: Arial; background-color: #fff; border: 1px solid #ccc; padding: 5px; border-radius: 3px;">
                            <strong>Date:</strong> {x}<br/>
                            <strong>Risk Level:</strong> {name}<br/>
                            <strong>Count:</strong> {y}
                        </div>
                    `}
                /> */}
            </Layer>
        </Chart>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {seriesData.map(risk => (
                <span key={risk.name} style={{ background: '#64B5F6', padding: '5px 10px', borderRadius: '2px', color: '#FFF', marginRight: '10px' }}>
                    {risk.name}
                </span>
            ))}
        </div>
    </div>
</Box>
)
};
export default LineChart2;
