import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAuth0 } from "@auth0/auth0-react";

const Bottombar = ({ setModalState, modalState }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { isAuthenticated } = useAuth0();

    const handleClick = () => {
        setModalState(true);
        console.log(modalState);
    };

    return (
        <Box
            zIndex={2}
            position="fixed"
            bottom={0}
            left={0}
            width="100%"
            display="flex"
            height={'35px'}
            justifyContent="flex-end" // Modified: Align items to the right
            p={2}
            sx={{ backgroundColor: colors.primary[500] }}
        >
            {/* ICONS */}
            <Box display="flex"> 
                <IconButton onClick={handleClick}>
                    <DownloadOutlinedIcon sx={{ color: colors.primary[1000], width: "20px", marginBottom: "12px" }} />
                    <Typography sx={{ color: colors.primary[1000], marginBottom: "14px", marginLeft: "5px" }} fontWeight={"bold"}>
                        Download Report
                    </Typography>
                </IconButton>
            </Box>
        </Box>
    );
};

export default Bottombar;
