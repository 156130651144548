import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Grid, Tooltip, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Skeleton from '@mui/material/Skeleton';

const CriticalExploitable = ({ jsonData, data, selectedDate, selectedCompanyName, godLoading }) => {

  return null}
//   const [open, setOpen] = useState(false);
//   const [PaperElevation, setPaperElevation] = useState(0);
//   const [vulnerabilities, setVulnerabilities] = useState([]);
//   const { user, isAuthenticated, isLoading } = useAuth0();

//   useEffect(() => {
//     if (selectedDate && selectedDate !== "" && selectedDate !== undefined) {
//       console.log('selectedDate is truthy');
//       console.log(selectedDate + " from criticalExploitable.jsx")

//     } else {
//       console.log(selectedDate + " from criticalExploitable.jsx")

//       console.log('selectedDate is falsy');
//     }
//     console.log(selectedDate + " from criticalExploitable.jsx")
//     if (selectedCompanyName && selectedDate && selectedDate !== "" && selectedDate !== undefined) {
     
//       // Make API call here to fetch the list of vulnerabilities
//       // Replace 'API_URL' with the actual API endpoint
//       console.log(selectedDate + " from criticalExploitable.jsx")
//       axios.get(`${process.env.REACT_APP_APIURL}/api/${selectedCompanyName}/visualizations/${selectedDate}/critical-vulns`)
//         .then(response => {
//           const vulnerabilities = response.data.map((vulnerability, index) => ({
//             ...vulnerability,
//             id: index + 1,
//           }));
//           console.log(vulnerabilities);
//           setVulnerabilities(vulnerabilities);
//         })
//         .catch(error => console.error(error)); }
    
//   }, [ selectedDate]);

//   const handleClick = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const columns = [
//     { field: 'host', headerName: 'Host', width: 150 },
//     { field: 'name', headerName: 'Name', width: 300,  editable: true },
//     { field: 'cve', headerName: 'CVE', width: 150 },
//     { field: 'synopsis', headerName: 'Synopsis', width: 400,  editable: true },
//     { 
//       field: 'seeAlso', 
//       headerName: 'See Also', 
//       width: 300,
//       renderCell: (params) => (
//         <a href={params.value} target="_blank" rel="noopener noreferrer">
//           {params.value}
//         </a>)}]
// if(!selectedDate && selectedDate === "" && selectedDate === undefined){
//   return null;
// } else return (
//     <>
//       <Box sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', cursor: 'pointer' }} onMouseEnter={() => setPaperElevation(5)} onMouseLeave={() => setPaperElevation(0)} onClick={handleClick}>
//         <Tooltip title="Click to view description">
//           <Card elevation={PaperElevation} sx={{ maxWidth: 250, height: 110, my: 2, mx: 'auto', backgroundColor: "#fcfcfc"}}>
//             <CardContent>
//               {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 100, textAlign: "center", marginLeft: 7 }}/> : <Typography variant="h5" color="textSecondary" sx={{ textAlign: 'center' }} gutterBottom>
//                 Extremely Critical Vulnerabilities
//               </Typography>}
//               {godLoading ? <Skeleton variant='text' sx={{ fontSize: '2rem', width: 70, textAlign: "center", marginLeft: 9 }}/> : <Typography variant="h3" component="div" style={{ cursor: 'pointer' }} sx={{ textAlign: 'center' }}>
//                 {data}
//               </Typography>}
//             </CardContent>
//           </Card>
//         </Tooltip>
//       </Box>
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{ style: { width: '70%'} } }>
//         <DialogTitle>Critical Vulnerabilities</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             The following vulnerabilities are classified as "Extremely Critical" and have a VPR score of 9.1 or higher:
//           </DialogContentText>
//           <div style={{ height: "500px", width: '100%' }}>
//             <DataGrid rows={vulnerabilities} columns={columns} pageSize={20} autoHeight={false} components={{
//               Toolbar: GridToolbarContainer,
//             }} componentsProps={{
//               toolbar: {
//                 children: [
//                   <GridToolbarQuickFilter />,
//                   <GridToolbarExport />
//                 ],
//               },
//             }}/>
//           </div>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Close</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

export default CriticalExploitable;
