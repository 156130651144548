// import React, { useState } from 'react';
// import { useAuth0 } from "@auth0/auth0-react";
// import { Button, TextField, Container, Typography, Box } from '@mui/material';

// function CustomSignup() {
//     const { loginWithRedirect } = useAuth0();

//     const isValidPassword = (password) => {
//         const specialCharRegex = /[!@#$%^&*]/;
//         const lowerCaseRegex = /[a-z]/;
//         const upperCaseRegex = /[A-Z]/;
//         const numberRegex = /[0-9]/;
    
//         return password.length >= 8 && 
//                specialCharRegex.test(password) && 
//                lowerCaseRegex.test(password) && 
//                upperCaseRegex.test(password) && 
//                numberRegex.test(password);
//     };
    


//     const [userData, setUserData] = useState({
//         email: '',
//         password: '',
//         company: '',
//         domain: ''
//     });

//     const [errors, setErrors] = useState({
//         email: false,
//         domain: false
//     });

//     const isValidEmail = (email) => {
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return emailRegex.test(email);
//     };

//     const isValidDomain = (domain) => {
//         const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
//         return domainRegex.test(domain);
//     };

//     const handleChange = (event) => {
//         const { name, value } = event.target;
//         setUserData(prevState => ({
//             ...prevState,
//             [name]: value
//         }));

//         if (name === "email") {
//             setErrors(prevState => ({
//                 ...prevState,
//                 email: !isValidEmail(value)
//             }));
//         } else if (name === "domain") {
//             setErrors(prevState => ({
//                 ...prevState,
//                 domain: !isValidDomain(value)
//             }));
//         } else if (name === "password") {
//             setErrors(prevState => ({
//                 ...prevState,
//                 password: !isValidPassword(value)
//             }));
//         }
//     };

   
// const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!isValidEmail(userData.email) || !isValidDomain(userData.domain)) {
//         return;
//     }

//     // Call the registration endpoint.
//     const response = await fetch(  `${process.env.REACT_APP_APIURL}/api/auth0/register`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify(userData)
//     });

//     const data = await response.json();

//     if (data.success) {
//         // Log the user in after successful registration.
//         await loginWithRedirect();
//     } else {
//         // Handle registration errors.
//     }
// };

//     return (

//         <Container component="main" maxWidth="xs">
//             <Box sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 mt: 8,
//             }}>
//                 <Typography component="h1" variant="h5">
//                     Sign Up
//                 </Typography>
//                 <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
//                 <TextField
//             variant="outlined"
//             margin="normal"
//             required
//             fullWidth
//             id="email"
//             label="Email Address"
//             name="email"
//             value={userData.email}
//             onChange={handleChange}
//             error={errors.email}
//             helperText={errors.email ? "Invalid email address." : ""}
//             autoFocus
//         />
//                    <TextField
//     variant="outlined"
//     margin="normal"
//     required
//     fullWidth
//     name="password"
//     label="Password"
//     type="password"
//     id="password"
//     value={userData.password}
//     onChange={handleChange}
//     error={errors.password}
//     helperText={errors.password ? "Password must contain special characters, both lower and uppercase characters, numbers, and be at least 8 characters long." : ""}
// />

//                     <TextField
//                         variant="outlined"
//                         margin="normal"
//                         required
//                         fullWidth
//                         name="company"
//                         label="Company"
//                         type="text"
//                         id="company"
//                         value={userData.company}
//                         onChange={handleChange}
//                     />
//                      <TextField
//             variant="outlined"
//             margin="normal"
//             required
//             fullWidth
//             name="domain"
//             label="Domain"
//             type="text"
//             id="domain"
//             value={userData.domain}
//             onChange={handleChange}
//             error={errors.domain}
//             helperText={errors.domain ? "Invalid domain name." : ""}
//         />
//                     <Button
//                         type="submit"
//                         fullWidth
//                         variant="contained"
//                         color="primary"
//                         sx={{ mt: 3, mb: 2 }}
//                     >
//                         Sign Up
//                     </Button>
//                 </Box>
//             </Box>
//         </Container>
       


//     );
// }

// export default CustomSignup;

import React, { useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, TextField, Container, Typography, Box, Grid, Snackbar, Alert } from '@mui/material';


function CustomSignup() {

    const { loginWithRedirect } = useAuth0();
    const [openSnackbar, setOpenSnackbar] = useState(false);


    const isValidPassword = (password) => {
        const specialCharRegex = /[!@#$%^&*]/;
        const lowerCaseRegex = /[a-z]/;
        const upperCaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;

        const unmetCriteria = [];

        if (password.length < 8) unmetCriteria.push("Must be at least 8 characters long");
        if (!specialCharRegex.test(password)) unmetCriteria.push("Must contain a special character");
        if (!lowerCaseRegex.test(password)) unmetCriteria.push("Must contain a lowercase character");
        if (!upperCaseRegex.test(password)) unmetCriteria.push("Must contain an uppercase character");
        if (!numberRegex.test(password)) unmetCriteria.push("Must contain a number");

        return unmetCriteria;
    };

    const [userData, setUserData] = useState({
        email: '',
        password: '',
        passwordVerification: '', // New field
        company: '',
        domain: ''
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false,
        domain: false
    });

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidDomain = (domain) => {
        const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
        return domainRegex.test(domain);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === "email") {
            setErrors(prevState => ({
                ...prevState,
                email: !isValidEmail(value)
            }));
        } else if (name === "domain") {
            setErrors(prevState => ({
                ...prevState,
                domain: !isValidDomain(value)
            }));
        } else if (name === "password") {
            const unmetRequirements = isValidPassword(value);
            setErrors(prevState => ({
                ...prevState,
                password: unmetRequirements.length > 0 ? unmetRequirements.join(', ') : false
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isValidEmail(userData.email) || !isValidDomain(userData.domain) || userData.password !== userData.passwordVerification) {
            return;
        }



        // Call the registration endpoint.
        const response = await fetch(`${process.env.REACT_APP_APIURL}/api/auth0/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userData)
        });

        const data = await response.json();

        if (data.success) {
            // Log the user in after successful registration.
            await loginWithRedirect();
        } else {
            console.log(response)
            // Check for user conflict error
            if (response.status === 409) {
                setOpenSnackbar(true); // Display the Snackbar
            }
            if (response.status === 501){
              setOpenSnackbar(true); // Display the Snackbar
            }
            // Handle other registration errors if needed.
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    return (
        <Typography align='center' paddingTop={20}> 
  

<Grid container spacing={0}>
  <Grid item xs={6}>
  <Box marginTop={18} marginRight={0}>
  <img src="/assets/arg-color-white.png" alt="Argus100 Logo" width="400" height="400" />

  </Box>
  </Grid>
  <Grid item xs={6} marginLeft={0}>
    <Container component="main" maxWidth="xs">
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 8,
      }}>
        <Typography component="h1" variant="h5">
          Sign Up for Argus100
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={userData.email}
            onChange={handleChange}
            error={errors.email}
            helperText={errors.email ? "Invalid email address." : ""}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={userData.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordVerification"
            label="Verify Password"
            type="password"
            id="passwordVerification"
            value={userData.passwordVerification}
            onChange={handleChange}
            error={userData.passwordVerification !== userData.password}
            helperText={userData.passwordVerification !== userData.password ? "Passwords do not match." : ""}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="company"
            label="Company"
            type="text"
            id="company"
            value={userData.company}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="domain"
            label="Domain"
            type="text"
            id="domain"
            value={userData.domain}
            onChange={handleChange}
            error={errors.domain}
            helperText={errors.domain ? "Invalid domain name." : ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Container>
  </Grid>
</Grid>
             <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    The user already exists. Please contact support or reset your password.
                </Alert>
            </Snackbar>
        </Typography>
    );
}

export default CustomSignup;

